.Form{
  text-align: left;

  .grid,
  .row{
    display: grid;
    grid-gap: 2em;

    @include responsive($tabletSmall){
      grid-template-columns: repeat(2, 1fr);
    }

    > *.--full{
      @include responsive($tabletSmall){
        grid-column: span 2;
      }
    }

    .files,
    .form-infos{
      @include responsive($tabletSmall){
        grid-column: span 2;
      }
    }

    .files,
    .form-infos{
      @include flex(flex-start);
      gap: 1.5em;

      @include responsive($tabletSmall, max){
        flex-direction: column;
      }
    }

    .form-infos{
      margin-top: 1.5em;

      @include responsive($tabletSmall){
        align-items: center;
      }

      > p{
        font: 400 10px $main-font;
        @include responsive-font(10);
        max-width: 45em;

        a{
          font-weight: 900;
          text-decoration: underline;
        }
      }

      > .Btn{
        @include responsive($tabletSmall){
          margin-left: auto;
        }
      }
    }
  }

  label:not(.Checkbox){
    font: 1000 11px $main-font;
    @include responsive-font(11);
    display: block;
    margin-bottom: 1.15em;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: $blue;

    sup,
    abbr{
      color: $green;
    }
  }

  .--error {
    font-size: 0.75em;
    color: $error-color;
    margin-top: 0.15em;
  }

  .Form__group,
  .Form__actions{
    *[disabled]{
      opacity: 0.5;
      cursor: not-allowed;
    }
  }

  .Form__actions{
    *[disabled]{
      pointer-events: none;
    }
  }

  .Form__output{
    &:not(:empty) {
      font: 700 14px $main-font;
      @include responsive-font(14);
      color: $valid-color;
      text-align: right;
      margin-top: 2em;

      .JobApply &{
        text-align: left;
        font: 700 16px $main-font;
        @include responsive-font(18);
      }

      .Newsletter &{
        text-align: left;
        display: inline-block;
        padding: 1em;
        border-radius: 10px;
        background: #7da237;
        color: $white;
      }
    }
  }
}
