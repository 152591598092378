.FurnitureCard{
  @include flex(center, flex-start);
  flex-direction: column;
  gap: 1em;
  padding: 1em 1em 2em;
  height: 100%;
  text-align: center;
  transition: transform 550ms $Power3EaseOut;

  @include noTouchHover() {
    transform: scale(0.95);
  }

  > .visual__container{
    @include imgRatio(500, 500);
    mix-blend-mode: darken;

    .visual{
      @include flex(center, center);

      img{
        max-width: 100%;
        max-height: 100%;
        object-fit: contain;
      }
    }
  }

  > span{
    font: 500 28px $main-font;
    letter-spacing: -0.025em;
    line-height: 1.3;
    @include responsive-font(32);
  }
}
