.RelatedArticles{
  overflow: hidden;

  .container {
    @include flex(center, center);
    flex-direction: column;
  }

  .container > h2{
    margin-bottom: 1em;
  }

  .container > .swiper{
    width: 100%;

    .swiper-wrapper{
      @include responsive($tabletSmall){
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 1.5em;
      }
    }

    .swiper-slide{
      display: flex;
    }
  }

  .container > .Btn{
    margin-top: 3em;
  }
}
