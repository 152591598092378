.Nav__toggle {
  @include flex(center, center);
  gap: 0.25em;
  pointer-events: all;
  cursor: pointer;
  position: relative;
  width: get-vw(25);
  height: get-vw(17);
  z-index: 10;
  transition: opacity 500ms $Power3EaseOut;
  
  @include responsive($tabletSmall, max){
    min-width: 25px;
    min-height: 17px;
  }

  @include responsive($menuBreakpoint) {
    display: none;
  }

  body.--show-submenu &{
    opacity: 0;
    pointer-events: none;
  }

  &:before,
  &:after{
    @include content();
  }

  &:before,
  &:after,
  > span{
    position: absolute;
    top: 50%; left: 50%;
    height: 0.15em;
    width: 100%;
    background-color: $blue-d1;
    border-radius: 20em;
    transition: background-color 450ms $Power3EaseOut,
                transform 450ms $Power3EaseOut;

    body:not(.--show-menu) .Header.--light:not(.--scroll) &{
      background-color: $white;
    }
  }

  &:before{
    transform: translate(-50%, -0.5em);

    body.--show-menu &{
      transform: translate(-50%, -50%) rotate(45deg);
    }
  }

  > span{
    transform: translate(-50%, -50%);
    transform-origin: 0% 0%;
    
    body.--show-menu &{
      transform: translate(-50%, -50%) scaleX(0);
      transform-origin: 100% 0%;
    }
  }

  &:after{
    transform: translate(-50%, 0.38em);

    body.--show-menu &{
      transform: translate(-50%, -50%) rotate(-45deg);
    }
  }
}
