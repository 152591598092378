.Back{
  display: inline-flex;
  align-items: center;
  gap: 0.5em;

  @include noTouchHover() {
    > .ellipse{
      transform: scaleX(-1) scale(0.85);
    }
  }

  > .ellipse{
    height: get-vw(24);
    width: get-vw(24);
    background: $blue-d1;
    transform: scaleX(-1);
    transition: transform 450ms $Power3EaseOut;

    @include responsive($tabletSmall, max){
      min-width: 24px;
      min-height: 24px;
    }

    @include responsive($desk){
      max-width: 24px;
      max-height: 24px;
    }
  }

  > span {
    font: 900 14px $main-font;
    color: $blue;
    @include responsive-font(14);
  }
}
