.Overlay {
  all: unset;
  @include cover(fixed);
  z-index: 80;
  cursor: pointer;
  background: $black;
  opacity: 0;
  pointer-events: none;
  transition: opacity 450ms $Power3EaseOut;

  body.--show-menu &,
  body.--show-submenu &,
  body.--show-search &{
    opacity: 0.8;
    pointer-events: all;
  }
}
