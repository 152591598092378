.ClientsSlider{
  overflow: hidden;

  .Home &{
    background: $white;
    padding-bottom: 0;
  }

  .head{
    @include flex(flex-start);

    @include responsive($tabletSmall, max){
      flex-direction: column;
      align-items: center;
    }

    @include responsive($tabletSmall){
      justify-content: space-between;
    }

    h2{
      margin-bottom: 1em;

      @include responsive($tabletSmall, max){
        text-align: center;
        margin-bottom: 1.5em;
      }

      @include responsive($tabletSmall){
        max-width: 80%;
      }
    }

    .swiper-navigation{
      @include responsive($tabletSmall, max){
        margin-bottom: 2em;
      }

      & > button{
        background: $beige-d2;
        border: none;
      }
    }
  }

  .swiper{
    .swiper-slide{
      max-width: 150px;

      @include responsive($tabletSmall){
        max-width: get-vw(220);
      }

      @include responsive($deskXL){
        max-width: 220px;
      }
    }
  }
}
