.Close,
.mapboxgl-popup-close-button{
  @include flex(center, center);
  border: 1px solid rgba($blue, 0.1);
  height: get-vw(48);
  width: get-vw(48);
  border-radius: 50%;
  transition: transform 450ms $Power3EaseInOut;

  @include responsive($tabletSmall, max){
    min-width: 48px;
    min-height: 48px;
  }

  @include responsive($desk){
    max-width: 48px;
    max-height: 48px;
  }

  @include noTouchHover() {
    transform: scale(0.85) rotate(-180deg);
  }

  &:before,
  &:after{
    @include content();
    position: absolute;
    height: 2px;
    width: 0.8em;
    border-radius: 20em;
    background: $blue;
  }

  &:before{
    transform: rotate(-45deg);
  }

  &:after{
    transform: rotate(45deg);
  }
}
