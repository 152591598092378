.ClientCard{
  padding: 1em;

  &, .visual__container > .visual{
    @include flex(center, center);
  }

  .visual__container{
    @include imgRatio(180, 100);
    mix-blend-mode: darken;

    .visual {
      transform: none;

      > img{
        max-width: 100%;
        max-height: 100%;
        height: auto;
        width: auto;
        object-fit: contain;
      }
    }
  }
}
