.NationalPresenceMap{
  @include imgRatio(601, 589);
  width: 100%;
  position: relative;

  @include responsive($tabletSmall){
    max-width: get-vw(601);
    margin-left: auto;
  }

  svg{
    @include cover();
    pointer-events: none;
  }

  // Order : top -> bottom
  .markers{
    .marker{
      position: absolute;
      z-index: 0;

      &.--agency{
        button{
          width: get-vw(56);
          height: get-vw(56);
          background: $blue;

          @include responsive($tabletSmall, max){
            width: get-vw(112);
            height: get-vw(112);
          }
        }
      }
      
      &.--warehouse{
        button{
          width: get-vw(34);
          height: get-vw(34);
          background: $blue-l2;
          
          @include responsive($tabletSmall, max){
            width: get-vw(70);
            height: get-vw(70);
          }
        }
      }

      &:not(.--active){
        .popup{
          opacity: 0;
          transform: translate(-50%, 15%);
          pointer-events: none;
        }
      }

      &.--active{
        z-index: 1;

        button{
          background: $green;
          transform: rotate(-45deg) scale(0.95);
        }
      }

      &:first-child{
        top: 6.25%; right: 42.65%;
      }

      &:nth-child(2){
        top: 16.2%; left: 35.2%;
      }

      &:nth-child(3){
        top: 28.15%; left: 28.45%;
      }
      
      &:nth-child(4){
        top: 26%; right: 41.8%;
      }

      &:nth-child(5){
        top: 32%; right: 36.5%;
      }

      &:nth-child(6){
        top: 42.35%; left: 25.25%;
      }

      &:nth-child(7){
        top: 51.45%; right: 30%;
      }

      &:nth-child(8){
        top: 60%; left: 26.25%;
      }

      &:nth-child(9){
        top: 78.2%; left: 28.35%;
      }
      
      &:nth-child(10){
        top: 73.65%; right: 23.15%;
      }
      
      button{
        @include flex(center, center);
        border-radius: 50%;
        transition: transform 350ms $Power3EaseOut;

        &:before,
        &:after{
          @include content();
          position: absolute;
          height: 2px;
          width: 10px;
          border-radius: 20em;
          background: $white;
        }

        &:after{
          transform: rotate(-90deg);
        }
      }

      .NationalPresencePopup{
        min-width: 350px;

        @include responsive($tabletSmall, max){
          display: none;
        }
        
        @include responsive($tabletSmall){
          min-width: get-vw(430);
          position: absolute;
          top: 100%; left: 50%;
          transform: translate(-50%, 5%);
        }

        @include responsive($desk){
          min-width: 430px;
        }
      }
    }
  }
}
