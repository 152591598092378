.PageHeaderArchiveFurnitures{
  padding-top: 10em;
  padding-bottom: 2.5em;
  overflow: hidden;

  .container > h1{
    margin-bottom: 1.25em;
    
    @include responsive($tabletSmall){
      margin-bottom: 0.75em;
    }
  }

  &.--show-dropdown{
    @include responsive($tabletSmall, max){
      .container > .wrapper{
        & > .Btn.--dropdown{
          span{
            &:nth-child(1) {
              display: none;
            }
          }
        }
      }
    }
  }

  &:not(.--show-dropdown) {
    @include responsive($tabletSmall, max){
      .container > .wrapper{
        & > .Btn.--dropdown{
          span{
            &:nth-child(2){
              display: none;
            }
          }
        }

        & > .head{
          display: none;
        }
      }
    }
  }

  .container > .wrapper{
    @include flex(flex-start);

    @include responsive($tabletSmall, max){
      flex-direction: column;
    }

    // Only for mobile (dropdown)
    & > .Btn.--dropdown{
      margin-top: 0;

      @include responsive($tabletSmall){
        display: none;
      }
    }

    & > .head{
      width: 100%;

      @include responsive($tabletSmall, max){
        margin: 2em 0 1em;
      }

      @include responsive($tabletSmall){
        max-width: space(19);
      }

      nav{
        ul {
          @include flex(center);
          flex-wrap: wrap;
          gap: 1em 2em;

          .Link{
            font: 900 18px $main-font;
            @include responsive-font(18);

            &.--active{
              > span{
                color: $green;
                background-image: linear-gradient($green, $green);
                background-size: 100% 1px;
                background-position: 0% 100%;
              }
            }

            &:not(.--active){
              > span{
                color: $blue;
                background-image: linear-gradient($blue, $blue); 
              }
            }
          }
        }
      }

      .checkboxes-grid{
        position: relative;
        margin-top: 1em;

        @include responsive($tabletSmall){
          margin-top: 0.75em;
        }

        > .inner{
          @include flex(center);
          flex-wrap: wrap;
          gap: 1em;

          // @include responsive($tabletSmall, max){
          //   flex-wrap: nowrap;
          //   white-space: nowrap;
          //   overflow-x: scroll;
          //   padding: 1em 20% 1em 0;
          //   width: 100%;
          // }
        }
      }

      nav ul,
      .checkboxes-grid > .inner{
        @include responsive($tabletSmall, max){
          align-items: flex-start;
          flex-direction: column;
        }
      }
    }

    & > .Btn.--blue{
      gap: 1.5em;

      @include responsive($tabletSmall, max){
        margin-top: 1em;
      }

      @include responsive($tabletSmall){
        margin-left: auto;
      }
    }
  }
}
