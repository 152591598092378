.Input{
  text-align: left;
  
  > input{
    padding: 1em;
    width: 100%;
    background: $beige;
    border-radius: 10px;

    &[type="search"]::-webkit-search-decoration,
    &[type="search"]::-webkit-search-cancel-button,
    &[type="search"]::-webkit-search-results-button,
    &[type="search"]::-webkit-search-results-decoration {
      display: none;
    }

    &::placeholder{
      color: $blue;
      opacity: 0.5;
    }
  }
}
