.ServiceSubmenuCard{
  @include flex(center);
  flex-direction: column;
  gap: 1.5em;
  padding: 1em 1em 2em;
  height: 100%;

  @include noTouchHover() {
    transform: scale(0.95);

    > .visual__container{
      transform: scale(1.15);
    }
  }

  &,
  > .visual__container{
    transition: transform 450ms $Power3EaseOut;
  }

  > .visual__container{
    @include imgRatio(130, 130);
    max-width: 130px;
    border-radius: 50%;
    overflow: hidden;

    @include responsive($tabletSmall){
      max-width: get-vw(130);
    }

    @include responsive($desk){
      max-width: 130px;
    }
  }

  > span{
    font: 500 16px $main-font;
    @include responsive-font(20);
    text-align: center;
    line-height: 1.2;
  }
}