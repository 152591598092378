.TechnicalSheetForm{
  width: 100%;
  position: relative;
  max-width: 90%;
  margin: 0 auto;
  padding: 5em 2em;
  
  @include responsive($tabletSmall){
    max-width: space(18);
    padding: 4em 6em;
  }

  > .Close{
    position: absolute;
    top: 1.5em; right: 1.5em;
  }

  > .row{
    grid-gap: 1em;
  }

  > .headline{
    font: 500 24px $main-font;
    @include responsive-font(24);
    line-height: 1.3;
    margin-bottom: 2em;
    text-align: center;

    @include responsive($tabletSmall){
      max-width: 50%;
      margin-left: auto;
      margin-right: auto;
    }

    strong{
      color: $green;
    }
  }

  .Form__output{
    margin-top: 1.25em;
  }
}
