.ContentSingle{
  .container > .visual__container.--thumbnail{
    @include imgRatio(1200, 590);
    margin-bottom: 3.5em;
    margin-top: -10em;
    
    @include responsive($tabletSmall){
      margin-top: get-vw(-350);
    }

    @include responsive($desk){
      margin-top: -20em;
    }
  }

  .container > .wswyg--content{
    width: 100%;
    color: $blue-text;

    @include responsive($tabletSmall){
      max-width: space(14);
      margin: 0 auto;
    }

    // h2, h3, h4, h5, h6{
    //   color: $blue;
    // }

    h2{
      @include responsive($tabletSmall){
        @include font-vw(48);
      }
    }

    .wp-block-embed.is-type-video{
      aspect-ratio: 16/9;
      position: relative;
      overflow: hidden;
      border-radius: 10px;
      width: 100%;

      *{
        @include cover();
      }
    }
  }
}
