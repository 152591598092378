.Copyright {
   @include flex(center, center);
   gap: 0.35em;

   > span {
    font: 900 12px $main-font;
    @include responsive-font(12);
   }

   svg > *{
    fill: $white;
   }
}
