.ContentPicture{
  .ArchiveJobs &{
    &:not(:first-of-type){
      padding-top: 0;
    }
  }

  &:nth-child(even),
  &.--invert{
    .container{
      @include responsive($tabletSmall){
        flex-direction: row-reverse;
      }
    }
  }

  &.--mobility{
    .container > .visual__container{
      @include imgRatio(1200, 850);

      .visual{
        transform: scale(1.15);
      }
    }
  }

  .container {
    @include flex(center);

    @include responsive($tabletSmall, max){
      align-items: flex-start;
      flex-direction: column;
    }
    
    @include responsive($tabletSmall){
      gap: space(2);
    }

    > *{
      width: 100%;

      @include responsive($tabletSmall){
        max-width: space(10);
      }
    }

    > .visual__container{
      @include imgRatio(600, 550);

      @include responsive($tabletSmall, max){
        margin-bottom: 2em;
        max-width: 530px;
      }
    }

    > .wrapper{
      .Back{
        margin-bottom: 1.5em;
      }

      h1, h2{
        margin-bottom: 0.35em;
      }

      h1, h2{
        .ContentSingle &{
          font: 500 28px $title-font;

          @include responsive($tabletSmall){
            @include font-vw(28);
          }
        }
      }

      .Btn{
        margin-top: 1.5em;
        text-decoration: none;
      }
    }
  }
}
