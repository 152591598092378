.JobCard{
  padding: 2em 3.5em 2em 2em; 

  > .Categories{
    margin-bottom: 1.5em;

    > li.subtitle{
      background: $beige-d2;
    }
  }

  > span{
    font: 500 20px $main-font;
    letter-spacing: -0.025em;
    @include responsive-font(28);
    line-height: 1.3;
  }

  > p{
    margin-top: 1em;
  }

  > .Btn{
    margin-top: 2em;
  }
}