.AllServices{
  .container > h2{
    margin-bottom: 0.75em;
  }

  .container > .grid{
    display: grid;
    grid-gap: 1em;
    
    @include responsive($mobile){
      grid-template-columns: repeat(2, 1fr);
    }

    @include responsive($tabletSmall){
      grid-template-columns: repeat(3, 1fr);
    }
  }
}
