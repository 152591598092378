.Pagination{
  margin-top: 5em;

  .Search &{
    display: none;
  }

  &.--jobs{
    > .wrapper{
      .timeline{
        background: $beige-d3;
      }
    }

    > .Btn{
      background: none;
    }
  }

  &, >.wrapper{
    @include flex(center, center);
    flex-direction: column;
  }

  > .wrapper{
    gap: 1em;

    p{
      font: 900 12px $main-font;
      @include responsive-font(12);
    }

    .timeline{
      width: 7.5em;
      height: 2px;
      border-radius: 20em;
      position: relative;

      .active{
        position: absolute;
        height: 100%;
        top: 0; left: 0;
      }
    }
  }

  > .Btn{
    margin-top: 2em;
    background: $white;
    border: 1px solid $green;
    color: $green;
    pointer-events: all;

    > *{
      pointer-events: none;
    }

    .Icon.--arrow-alt{
      transform: rotate(90deg);

      svg > *{
        stroke: $green;
      }
    }
  }
}
