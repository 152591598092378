.OurStoryCard{
  width: 100%;
  position: relative;

  @include responsive($mobile){
    max-width: 50%;
  }

  &.--with-visual{
    &[data-year="2007"],
    &[data-year="2012"]{
      > .inner{
        padding-top: 3em;
        padding-bottom: 7em;
      }
    }

    > .inner{
      padding-top: 7.5em;
    }
  }

  &:nth-child(odd){
    &, > .inner{
      @include responsive($mobile){
        margin-left: auto;
      }
    }
  }

  &:nth-child(even){
    .bullet{
      @include responsive($mobile){
        left: auto; right: 0;
        transform: translate(50%, -50%);
      }
    }
  }

  .bullet.--green,
  > .inner.--green{
    background: $green;
  }

  .bullet.--blue,
  > .inner.--blue{
    background: $blue;
  }

  // Bullet
  .bullet{
    @include content();
    position: absolute;
    top: 50%; left: 0;
    transform: translate(-50%, -50%);
    height: get-vw(20); width: get-vw(20);
    border-radius: 50%;
    background: $beige-d3;
    border: 3px solid $blue-pastel;
    z-index: 1;

    @include responsive($tabletSmall, max){
      min-width: 20px;
      min-height: 20px;
    }

    @include responsive($desk){
      max-width: 20px;
      max-height: 20px;
    }
  }

  > .inner{
    position: relative;
    overflow: hidden;
    padding: 2.5em;
    background: $blue; // Color by default
    width: 100%;
    
    @include responsive($mobile, max){
      margin-left: 3em;
      max-width: 90%;
    }

    @include responsive($mobile){
      max-width: 90%;
    }

    @include responsive($tabletSmall){
      max-width: get-vw(340);
    }

    > .wrapper{
      @include flex(flex-start);
      flex-direction: column;
      position: relative;
      z-index: 1;

      > time{
        display: inline-block;
        margin-bottom: 1.25em;
        padding: 0.25em 1em;
      }

      > span{
        font: 400 20px $main-font;
        @include responsive-font(24);
        letter-spacing: -0.025em;
        line-height: 1.2;
      }
    }

    > .visual__container{
      @include cover();
      z-index: 0;
    }
  }
}
