.ValueCard{
  padding: 0.5em 2em 4em;
  border: 1px dashed $green;
  height: 100%;

  > h3{
    font: 500 20px $main-font;
    letter-spacing: -0.025em;
    @include responsive-font(40);
  }

  > .wswyg--content{
    margin-top: 1em;
  }
}
