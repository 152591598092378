.HomeIntro{
  @include cover(fixed);
  z-index: 1000;

  .MainLogo,
  .gallery > .visual__container{
    position: fixed;
    top: 50%; left: 50%;
    transform: translate(-50%, -50%);
  }

  .gallery{
    .visual__container{
      &:first-child{
        @include imgRatio(250, 250);
        width: get-vw(250);

        @include responsive($tabletSmall, max){
          min-width: 170px;
        }

        @include responsive($desk){
          max-width: 250px;
        }
      }

      &:nth-child(2){
        @include imgRatio(60, 300);
        width: get-vw(60);

        @include responsive($tabletSmall, max){
          min-width: 60px;
        }

        @include responsive($desk){
          max-width: 60px;
        }
      }

      &:nth-child(3){
        @include imgRatio(170, 230);
        width: get-vw(170);

        @include responsive($tabletSmall, max){
          min-width: 170px;
        }

        @include responsive($desk){
          max-width: 170px;
        }
      }

      &:nth-child(4){
        @include imgRatio(360, 224);
        width: get-vw(360);

        @include responsive($tabletSmall, max){
          min-width: 170px;
        }

        @include responsive($desk){
          max-width: 360px;
        }
      }
      
      &:nth-child(5){
        @include imgRatio(60, 300);
        width: get-vw(60);

        @include responsive($tabletSmall, max){
          min-width: 60px;
        }

        @include responsive($desk){
          max-width: 60px;
        }
      }
    }
  }
}
