.Newsletter{
  margin-top: 2em;

  > span{
    font-weight: 900;
    margin-bottom: 1em;
  }

  form {
    position: relative;
    grid-gap: 1em;

    .grid,
    .row{
      @include responsive($tabletSmall){
        grid-template-columns: 1fr;
      }
    }

    .Input{
      @include responsive($tabletSmall, max){
        flex: 1;
      }

      @include responsive($tabletSmall){
        width: get-vw(310);
      }

      @include responsive($desk){
        max-width: 310px;
      }

      label{
        position: absolute;
        top: -99999px; left: -99999px;
      }

      input{
        background: none;
        border: 1px solid rgba($white, 0.2);

        &::placeholder{
          color: $white;
        }
      }
    }

    .Checkbox{
      align-items: flex-start;
      padding: 0;
      
      @include responsive($tabletSmall){
        max-width: 24em;
      }
      
      > input{
        max-width: get-vw(16);
        max-height: get-vw(16);
        border: none;

        @include responsive($tabletSmall, max){
          min-width: 16px;
          min-height: 16px;
        }

        @include responsive($desk){
          max-width: 16px;
          max-height: 16px;
        }

        &:checked{
          background: none;

          & ~ .check{
            background: $black;
            border: none;
          }
        }
      }

      > .check{
        margin-top: 0.125em;
      }
      
      > span{
        font: 400 12px $main-font;
        @include responsive-font(12);
        color: $white;
        opacity: 0.6;
        flex: 1;
        pointer-events: all;

        a{
          text-decoration: underline;
        }
      }
    }

    .Form__group{
      > span.--error{
        margin-top: 1em;
        background: #f95757;
        color: $white;
        padding: 1em;
        border-radius: 5px;
      }
    }

    .Form__output{
      margin-top: 1.25em;
    }

    .Form__actions{
      @include responsive($tabletSmall, max){
        margin-top: 2em;
      }

      @include responsive($tabletSmall){
       position: absolute;
       top: 0; right: 2em; 
      }

      .Btn{
        background: $white;
        color: $blue;
        padding: 0.7em 1.5em;

        @include responsive($tabletSmall){
          min-height: 3.5em;
        }

        .Icon.--arrow-alt svg > *{
          stroke: $blue;
        }
      }
    }
  }
}
