.hiring-profiles-gallery{
  @include flex(center, center);
  margin-bottom: 1.5em;

  > *{
    @include imgRatio(48, 48);
    width: get-vw(48);
    border-radius: 50%;
    outline: 2px solid $beige;

    @include responsive($tabletSmall, max){
      min-width: 38px;
    }
        
    @include responsive($desk){
      max-width: 48px;
    }

    .Hiring &{
      outline-color: $green;
    }

    & + *{
      margin-left: -1em;
    }
  }

  .more{
    position: relative;
    border-radius: 50%;
    overflow: hidden;

    .ArchiveReferences &{
      display: none;
    }

    .inner{
      @include cover();
      @include flex(center, center);
      background: $beige-d2;

      .Hiring &{
        background: $green-d1; 
      }

      &:before,
      &:after{
        @include content();
        position: absolute;
        height: 2px;
        width: 0.5em;
        background: $blue;
        border-radius: 20em;
      
        .Hiring &{
          background: $white;
        }
      }

      &:after{
        transform: rotate(-90deg);
      }
    }
  }
}
