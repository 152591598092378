.References{
  padding: 8em 0;
  position: relative;
  overflow: hidden;

  .container{
    z-index: 1;
  }

  .wrapper{
    @include flex(center, center);
    flex-direction: column;

    @include responsive($tabletSmall){
      max-width: space(12);
      margin: 0 auto;
    }

    > h2,
    > .wswyg--content{
      text-align: center;
    }

    > .wswyg--content{
      margin-top: 2em;
      filter: opacity(0.7);

      @include responsive($tabletSmall){
        max-width: get-vw(640);
        margin-left: auto;
        margin-right: auto;
      }
    }

    > .Btn{
      margin-top: 2em;
      color: $blue;

      .Icon.--arrow-alt svg > *{
        stroke: $blue;
      }
    }
  }

  .map{
    @include imgRatio(573, 561);
    width: get-vw(573);

    position: absolute;
    z-index: 0;

    @include responsive($tabletSmall, max){
      width: 100%; height: 100%;
      top: 50%; left: 50%;
      transform: translate(-50%, -50%);
      opacity: 0.35;
    }

    @include responsive($tabletSmall){
      top: -5%; right: -5%;
    }

    @include responsive($desk){
      max-width: 570px;
    }

    > svg {
      @include cover();
    }
  }
}
