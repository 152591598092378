h1, h2, h3, h4, h5, h6{
   font-weight: 500;

   strong{
      font-weight: 700;
   }
}

h1{
   font: 500 38px / 1.15 $title-font;
   letter-spacing: -0.025em;

   @include responsive($tabletSmall){
     @include font-vw(54);
   }

   @include responsive($deskXXL){
      @include font-vw(44);
    }
}

h2{
   font: 500 34px / 1.2 $title-font;

   @include responsive($tabletSmall){
     @include font-vw(46);
   }

   @include responsive($deskXXL){
      @include font-vw(38);
   }
}

h3{
   font: 500 30px $title-font;

   @include responsive($tabletSmall){
     @include font-vw(36);
   }

   @include responsive($deskXXL){
      @include font-vw(30);
   }
}

h4{
   font: 500 26px $title-font;

   @include responsive($tabletSmall){
     @include font-vw(30);
   }

   @include responsive($deskXXL){
      @include font-vw(24);
   }
}

h5{
   font: 500 22px $title-font;

   @include responsive($tabletSmall){
     @include font-vw(28);
   }

   @include responsive($deskXXL){
      @include font-vw(22);
    }
}

h6{
   font: 500 18px $title-font;

   @include responsive($tabletSmall){
     @include font-vw(24);
   }
   
   @include responsive($deskXXL){
      @include font-vw(20);
   }
}

.subtitle,
.tag{
   font: 1000 11px $title-font;
   text-transform: uppercase;
   @include responsive-font(11);

   &.--small{
      font: 1000 9px $title-font;
      @include responsive-font(9);
   }

   &.--large{
      font: 1000 13px $title-font;
      @include responsive-font(13);
   }
}

.tag{
   border-radius: 20em;
   padding: 0.7em 1.55em;
}

.display{
   font: 500 80px $main-font;
   letter-spacing: -0.025em;
   line-height: 1.1;
   @include responsive-font(150);
}

p {
   &.no__results {
      text-align: center;
      opacity: 0.2;
   }
}

.--s{
   @include responsive-font(14);
}

.wswyg--content {
   &.--small{
      h1{
         font: 500 30px $title-font;

         @include responsive($tabletSmall){
            @include font-vw(30);
          }
      }

      h2{
         font: 500 28px $title-font;

         @include responsive($tabletSmall){
           @include font-vw(22.5);
         }
      }

      h3{
         font: 500 26px $title-font;

         @include responsive($tabletSmall){
           @include font-vw(20.1);
         }
      }

      h4{
         font: 500 24px $title-font;

         @include responsive($tabletSmall){
           @include font-vw(17.4);
         }
      }

      h5{
         font: 500 22px $title-font;

         @include responsive($tabletSmall){
           @include font-vw(15);
         }
      }

      h6{
         font: 500 20px $title-font;

         @include responsive($tabletSmall){
           @include font-vw(15);
         }
      }

      p, ul, ol, li, a{
         font-size: 14px;
         @include responsive-font(14);
      }
   }

   text-align: left;

   * { color: inherit }

   > * + * {
      margin-top: 1em;
   }

   h1{
      font: 500 50px $title-font;

      @include responsive($tabletSmall){
         @include font-vw(50);
       }
   }

   h2{
      font: 500 28px $title-font;
      color: $blue-d1;

      @include responsive($tabletSmall){
        @include font-vw(37.5);
      }
   }

   h3{
      font: 500 26px $title-font;
      color: $green;

      @include responsive($tabletSmall){
        @include font-vw(33.5);
      }
   }

   h4{
      font: 500 24px $title-font;
      color: $blue-d1;

      @include responsive($tabletSmall){
        @include font-vw(29);
      }
   }

   h5{
      font: 500 22px $title-font;
      color: $green;
      font-style: italic;
      text-decoration: underline;

      @include responsive($tabletSmall){
        @include font-vw(25);
      }
   }

   h6{
      font: 500 20px $title-font;
      font-style: italic;
      color: $blue-d1;

      @include responsive($tabletSmall){
        @include font-vw(22);
      }
   }

   ul,
   ol {
      padding-left: 1em;

      > li + li {
         margin-top: 0;
      }

      code {
         margin-top: 1em;
         margin-bottom: 1em;
      }
   }

   ul{
      list-style: disc;
   }

   ol {
      list-style-type: decimal;
   }

   ul, ol{
      li{
         display: list-item;
      }
   }

   em{
      font-style: italic;
   }

   strong{
      font-weight: 1000;
   }

   figure {
      margin: 3em auto;
      overflow: hidden;

      &:last-child {
         margin-bottom: 0px;
      }

      img {
         display: block;
      }
   }

   blockquote {
      padding-left: 2em;
      border-left: 2px solid darken($blue-pastel, 0.7);

      p{
         font: 500 20px $main-font;
         line-height: 1.4;
         
         @include responsive($tabletSmall){
            @include font-vw(24);
         }
      }

      cite{
         display: block;
         margin-top: 2em;
      }
   }

   a {
      font-weight: 500;
      text-decoration: underline;
   }

   img{
      border-radius: 10px;
      max-width: 100%;

      &.aligncenter{
         margin: 0 auto;
      }

      &.alignleft{
         margin-left: 0;
      }

      &.alignright{
         margin-left: auto;
      }
   }

   .plyr__container{
      aspect-ratio: 16/9;
      border-radius: 10px;
      margin: 2.5em 0 0;

      .plyr__control--overlaid {
         background: $blue;

         svg{
            fill: $white;
         }
      }
   }

   table{
      thead{
         color: $blue;
      }

      td{
         border: 1px solid #c7c7c7;
         padding: 1em 0.5em;
      }
   }
}
