.Number {
  @include flex(center, center);
  flex-direction: column;
  position: relative;
  text-align: center;
  // flex: 1 0 100%;

  // @include responsive($mobile) {
  //   flex: 1 0 40%;
  // }

  // @include responsive($tablet) {
  //   flex: 1;
  // }

  > span,
  > p{
    z-index: 1;
  }

  > span {
    font: 500 80px $main-font;
    letter-spacing: -0.025em;
    line-height: 1;
    @include responsive-font(120);
  }

  > p {
    font: 400 18px $main-font;
    @include responsive-font(20);

    @include responsive($tabletSmall) {
      max-width: get-vw(210);
      margin: 0.5em auto 0;
    }

    @include responsive($desk) {
      max-width: 210px;
    }
  }

  > .visual__container{
    @include imgRatio(120, 120);
    max-width: get-vw(120);
    margin-bottom: -2.75em;
    z-index: 0;

    @include responsive($tabletSmall, max){
      min-width: 120px;
    }

    @include responsive($desk){
      max-width: 120px;
    }

    &:after{
      @include content();
      position: absolute;
      bottom: 0; left: 0;
      width: 100%;
      height: 3.75em;
      background: linear-gradient(180deg, rgba(46, 108, 125, 0) 0%, $blue 100%);
    }
  }
}