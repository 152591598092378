.Sharing{
  ul{
    @include flex(center, center);
    gap: 1em;

    li {
      a{
        .ellipse{
          width: get-vw(48);
          height: get-vw(48);
          background: $beige-d1;

          @include responsive($tabletSmall, max){
            min-width: 48px;
            min-height: 48px;
          }

          @include responsive($desk){
            max-width: 48px;
            max-height: 48px;
          }
        }
      }
    }
  }
}
