.Gutenberg{
  padding-top: 3.5em;
  padding-bottom: 3.5em;

  @include responsive($tabletSmall){
    padding-top: 10em;
  }

  .Service &{
    @include responsive($tabletSmall){
      padding-bottom: 7em;
    }
  }

  .container.--main {
    color: $blue-text;

    > * + * {
      margin-top: 1.25em;
    }

    > div + div,
    > div + section,
    > div + figure,
    > section + div,
    > section + section,
    > section + figure,
    > figure + div,
    > figure + section,
    > figure + figure{
      margin-top: 5em;
    }

    > div + h2,
    > section + h2,
    > figure + h2,
    > div + h3,
    > section + h3,
    > figure + h3,
    > div + h4,
    > section + h4,
    > figure + h4,
    > div + h5,
    > section + h5,
    > figure + h5,
    > div + h6
    > section + h6,
    > figure + h6{
      margin-top: 3.5em;
    }

    iframe{
      border-radius: 30px;
      overflow: hidden;
    }

    h2, h3, h4, h5, h6{
      section:not(.Numbers) &{
        color: $blue;
      }
    }

    > p + ul,
    > p + ol{
      margin-top: 0;
    }

    ul{
        padding-left: 1em;
        list-style: disc;
     }

    ol {
      padding-left: 1.25em;
      list-style-type: decimal;
    }

     ul,
     ol {
        > li + li {
          //  margin-top: 0.5em;
        }

        code {
           margin-top: 1em;
           margin-bottom: 1em;
        }
     }
  }

  // Custom blocks
  .PageHeader,
  .BannerColorContent,
  .Numbers,
  .ContentPicture,
  .ContentPicture.blockcontentpicture,
  .ContactBanner,
  .RelatedFurnituresTypes,
  .NationalPresence,
  .wp-block-post-featured-image{
    margin-top: 2em;
    margin-bottom: 2em;

    margin-left: -2em;
    margin-right: -2em;

    @include responsive($tabletSmall){
      margin-left: calc(-100vw / 24);
      margin-right: calc(-100vw / 24);
    }
  }

  .NationalPresence{
    background: $white;
  }

  .ContentPicture,
  .BannerColorContent{
    padding: 0;
  }

  // Basic blocks
  .wp-block-columns{
    gap: 3em;

    @include responsive($tabletSmall){
      // align-items: center !important;
    }
  }

  .wp-block-columns{
    ul{
      list-style: disc;
   }

   ol {
      list-style-type: decimal;
   }

   em{
      font-style: italic;
   }

   strong{
      font-weight: 1000;
   }
  }

  .wp-block-column{
    .wp-block-image{
      height: 100%;
      width: 100%;
    }
  }

  .wp-block-image{
    border-radius: 10px;
    overflow: hidden;

    &.size-large{
      img{
        width: 100%;
      }
    }

    &,
    > figure{
      img{
        height: 100% !important;
        width: 100% !important;
        object-fit: cover;
      }
    }

    > figure{
      margin: 0;
      height: 100%;
      width: 100%;
    }
  }

  .wp-block-embed{
    display: flex;

    &.alignleft{ justify-content: flex-start }

    &.aligncenter{ justify-content: center }

    &.alignright{ justify-content: flex-end }

    .wp-block-embed__wrapper{
      aspect-ratio: 16/9;
      position: relative;
      width: 100%;
      max-width: 700px;

      > iframe{
        @include cover();
      }
    }
  }

  .wp-block-buttons{
    gap: 1em;
  }

  .wp-block-button__link.wp-element-button{
    
  }

  h1.has-background, 
  h2.has-background, 
  h3.has-background, 
  h4.has-background, 
  h5.has-background, 
  h6.has-background{
    padding: 0.5em 2.375em;
  }
}
