.Accordion{
  position: relative;
  background: $beige-d1;
  transition: opacity 500ms $Power3EaseOut;

  &.--transition,
  &.--hide{
    opacity: 0;
    pointer-events: none;
  }

  &.--hide{
    position: absolute;
    top: 0;
    width: 100%;
  }

  // When the accordion is open
  &.--active{
    .head > .Icon{
      transform: scaleY(-1);
    }
  }

  .head{
    @include flex(flex-start);
    padding: 0.8em 1.2em;
    color: $blue;
    width: 100%;
    transition: color 450ms $ease-in-out-circ,
                background 450ms $ease-in-out-circ;

    > span{
      font-weight: 1000;
      flex: 1;
      padding-right: 1em;
    }

    > .Icon{
      margin-top: 0.7em;
      transition: transform 450ms $Power1EaseInOut;

      svg > *{
        transition: opacity 450ms $Power1EaseInOut, 
                    stroke 450ms $Power1EaseInOut;
      }
    }
  }

  .content{
    max-height: 0;
    overflow: hidden;
    transition: max-height 450ms $ease-in-out-circ;
    max-width: 40em;

    .inner{
      padding: 0 1em 2em;
    }
  }
}
