.NotFound{
  padding-top: 10em;
  padding-bottom: 5em;

  > .container{
    @include flex(center, center);
    flex-direction: column;
  }

  .visual__container{
    @include imgRatio(272, 218);
    margin-bottom: 1em;

    @include responsive($tabletSmall, max){
      max-width: 250px;
    }
    
    @include responsive($tabletSmall){
      max-width: get-vw(272);
    }
  }

  p{
    margin: 1.5em 0 2em;
  }
}
