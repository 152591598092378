.PageHeaderFurniture{
  padding-top: 8em;
  padding-bottom: 5em;

  .container {
    @include flex(flex-start);

    @include responsive($tabletSmall, max){
      flex-direction: column-reverse;
    }

    & > *{
      width: 100%;

      @include responsive($tabletSmall){
        max-width: space(10);
      }
    }
  }

  .container > .gallery{
    overflow: hidden;

    @include responsive($tabletSmall, max){
      margin-top: 3em;
    }

    @include responsive($tabletSmall){
      position: sticky;
      top: 1.5em;
    }

    // Parent + Children
    .swiper.--main,
    .swiper.--thumbs{
      .swiper-slide{
        .visual__container{
          mix-blend-mode: darken;
        }
      }
    }

    // Parent
    .swiper.--main{
      .swiper-slide{
        padding: 2em;

        @include responsive($tabletSmall){
          padding: 5em;
        }

        .visual__container{
          @include imgRatio(500, 500);

          .visual{
            @include flex(center, center);

            img{
              max-width: 100%;
              height: auto;
              max-height: 100%;
              object-fit: contain;
            }
          }
        }
      }
    }

    // Children
    .swiper.--thumbs{
      margin-top: 1em;

      .swiper-slide{
        cursor: pointer;
        padding: 1.5em;
        max-width: 100px;
        transition: background 600ms $ease,
                    transform 600ms $ease;
        
        @include responsive($tabletSmall){
          max-width: get-vw(100);
        }

        &:not(.swiper-slide-thumb-active){
          @include noTouchHover() {
            transform: scale(0.85);
          }
        }

        &.swiper-slide-thumb-active{
          background: $beige-d2;
          transform: scale(0.85);
        }

        .visual__container{
          @include imgRatio(500, 500);

          .visual{
            @include flex(center, center);

            img{
              max-width: 100%;
              height: auto;
            }
          }
        }
      }
    }
  }

  .container > .wrapper{
    @include responsive($tabletSmall){
      margin-left: auto;
    }

    & > .Breadcrumb{
      margin-bottom: 1.5em;
    }

    & > .wswyg--content{
      margin-top: 1.5em;
    }

    & > nav.links{
      margin-top: 2em;

      ul{
        display: grid;
        overflow: hidden;
  
        @include responsive($tabletSmall){
          grid-template-columns: repeat(2, 1fr);
        }
  
        li > *{
          @include flex(center, center);
          gap: 0.45em;
          font-weight: 900;
          width: 100%;
          padding: 1.25em 2em;
          transition: background 500ms $ease-out-quad;

          &.--bg-beige{
            @include noTouchHover() {
              background: $beige-d2;
            }
          }

          &.--bg-green{
            @include noTouchHover(){
              background: $green-d1;
            }
          }
        }
      }
    }

    & > .infos{
      display: grid;
      grid-gap: 1.5em;
      margin-top: 3.5em;

      > div{
        > span{
          font: 500 20px $main-font;
          padding-bottom: 0.35em;
          border-bottom: 1px dashed $green; 
          margin-bottom: 0.75em;
          display: block;

          @include responsive($tabletSmall){
            @include font-vw(28);
          }
        }

        > .wswyg--content{

        }
      }
    }
  }

  > .modal{
    @include cover(fixed);
    z-index: 100;
    background: rgba($black, 0.7);
    padding-top: 5em;
    padding-bottom: 2.5em;
    overflow-y: scroll;
    transition: opacity 450ms $ease;

    body:not(.--show-furniture-modal) &{
      opacity: 0;
      pointer-events: none;

      .Form{
        transform: translate(0, 10%);
      }
    }

    .Form{
      transition: transform 750ms $Power3EaseOut;
    }
  }
}
