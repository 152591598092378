.Checkbox{
  gap: 0.75em;
  padding: 0.75em 1.5em 0.75em 1em;
  position: relative;
  transition: transform 450ms $Power3EaseOut;

  @include noTouchHover() {
    transform: scale(0.95);
  }

  .Newsletter &{
    @include noTouchHover() {
      transform: scale(1);
    }
  }

  &[href]{
    border: 1px solid $beige-d2;
    border-radius: 10px;

    &.--active{
      background: $green-d1;
      border-color: $green-d1;

      .Icon.--tick{
        opacity: 1;
        transform: scale(1);
      }

      > span{
        color: $white;
      }
    }
  }

  &, 
  & > .check{
    @include flex(center, center);
  }

  > input{
    all: unset;
    @include cover();
    cursor: pointer;
    border-radius: 10px;

    &:not(:checked){
      border: 1px solid $beige-d2;
    }

    &:checked{
      background: $green;

      & ~ .check{
        background: $green-d1;
        border-color: $green-d1;

        .Icon.--tick{
          opacity: 1;
          transform: scale(1);
        }
      }

      & ~ span{
        color: $white;
      }
    }
  }

  > input,
  > .check{
    transition: border-color 500ms $Power3EaseOut,
                background 500ms $Power3EaseOut;
  }

  > .check,
  > span{
    position: relative;
    z-index: 1;
    pointer-events: none;
  }

  > .check{
    height: get-vw(16);
    width: get-vw(16);
    border: 1.5px solid #CCCBC9;
    border-radius: 3px; 

    @include responsive($tabletSmall, max){
      min-height: 16px;
      min-width: 16px;
    }

    @include responsive($desk){
      max-width: 16px;
      max-height: 16px;
    }

    .Icon.--tick {
      opacity: 0;
      transform: scale(0.2);
      transition: transform 250ms $Power3EaseInOut;

      svg > *{
        stroke: $white;
      }
    }
  }
        
  > span{
    font: 700 12px $main-font;
    @include responsive-font(14);
    color: $blue;
    transition: color 500ms $Power3EaseOut;
  }
}