.App {
  width: 100%;
  min-height: 100vh;
  position: relative;
  z-index: 10;

  html:not(.no-js) body.--loading & {
    pointer-events: none;
  }
}

.container {
  width: 100%;
  padding: 0 2em;
  position: relative;

  @include responsive($tabletSmall) {
    padding: 0 calc(100vw / 24);
  }

  &.--col-2{
    @include responsive($tabletSmall) {
      padding: 0 calc(100vw / 12);
    }
  }
}

section {
  padding: 5em 0;
}
