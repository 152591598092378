/**
* AVENIR LT STD
*/

@font-face {
  font-family: avenir-lt-std;
  font-weight: 500;
  font-style: normal;
  font-display: block;
  src: url('../fonts/AvenirLTStd-Medium.woff2') format("woff2"), url('../fonts/AvenirLTStd-Medium.woff') format("woff");
}

@font-face {
  font-family: avenir-lt-std;
  font-weight: 700;
  font-style: normal;
  font-display: block;
  src: url('../fonts/AvenirLTStd-Heavy.woff2') format("woff2"), url('../fonts/AvenirLTStd-Heavy.woff') format("woff");
}

/**
* AVENIR
*/

@font-face {
  font-family: avenir;
  font-weight: 400;
  font-style: normal;
  font-display: block;
  src: url('../fonts/Avenir-Roman.woff2') format("woff2"), url('../fonts/Avenir-Roman.woff') format("woff");
}

@font-face {
  font-family: avenir;
  font-weight: 500;
  font-style: normal;
  font-display: block;
  src: url('../fonts/Avenir-Medium.woff2') format("woff2"), url('../fonts/Avenir-Medium.woff') format("woff");
}

@font-face {
  font-family: avenir;
  font-weight: 900;
  font-style: normal;
  font-display: block;
  src: url('../fonts/Avenir-Heavy.woff2') format("woff2"), url('../fonts/Avenir-Heavy.woff') format("woff");
}


@font-face {
  font-family: avenir;
  font-weight: 1000;
  font-style: normal;
  font-display: block;
  src: url('../fonts/Avenir-Black.woff2') format("woff2"), url('../fonts/Avenir-Black.woff') format("woff");
}
