.Specialities{
  text-align: center;
  overflow: hidden;

  .container > h2{
    margin-bottom: 1em;
  }

  .container > .swiper{
    .swiper-wrapper{
      @include responsive($tabletSmall){
        @include flex(stretch, center);
        gap: 1em;
      }
    }

    .swiper-slide{
      max-width: 90%;

      @include responsive($mobile){
        max-width: 400px;
      }

      @include responsive($tabletSmall){
        max-width: get-vw(270);
      }
      
      @include responsive($desk){
        max-width: 270px;
      }
    }
  }
}
