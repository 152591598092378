.AllFurnitures{
  padding-top: 3.5em;

  .container > h2{
    margin-bottom: 0.75em;
  }

  .container > .grid{
    // @include flex();
    // flex-wrap: wrap;
    display: grid;
    grid-gap: 2em;

    @include responsive($mobile){
      grid-template-columns: repeat(2, 1fr);
    }

    @include responsive($tabletSmall){
      grid-template-columns: repeat(4, 1fr);
    }

    @for $i from 1 through 350 {
      > *:nth-child(#{$i}) {
        order: $i;
      }
    }

    > *:first-child{
      order: 9;
    }
  }

  .container > p{
    text-align: center;

    .Search &{
      text-align: left;
    }
  }
}
