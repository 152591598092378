.ScrollTop{
  position: fixed;
  bottom: 0; right: 0;
  z-index: 100;
  cursor: pointer;
  padding: 1em;
  transition: opacity 500ms $ease;

  &.--hide{
    opacity: 0;
    pointer-events: none;
  }

  @include noTouchHover() {
    > .inner{
      transform: scale(1.1);

      .Icon.--arrow-alt{
        transform: translate(0, -10%) rotate(-90deg);
      }
    }
  }
  
  > .inner{
    @include flex(center, center);
    height: get-vw(50);
    width: get-vw(50);
    border-radius: 50%;
    background-color: $base;

    @include responsive($tabletSmall, max){
      min-height: 50px;
      min-width: 50px;
    }

    @include responsive($deskXXL){
      max-width: 50px;
      max-height: 50px;
    }
    
    &, 
    .Icon.--arrow-alt{
      transition: transform 500ms $ease;
    }

    .Icon.--arrow-alt{
      transform: rotate(-90deg);
    }
  }
}
