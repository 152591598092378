.PageHeaderContact{
  padding-top: 12em;
  padding-bottom: 20em;
  position: relative;

  .container {
    z-index: 2;
    
    > .wrapper{
      @include responsive($tabletSmall){
        max-width: space(18);
        margin: 0 auto;
      }

      p{
        margin-top: 1.5em;
        filter: opacity(0.75);
      }
    }
  }

  .gallery{
    @include responsive($tabletSmall, max){
      opacity: 0.35;
    }

    .visual__container{
      position: absolute;
      will-change: transform;

      &:first-child{
        @include imgRatio(275, 290);
        top: 50%; left: -5%;
        max-width: get-vw(275);

        @include responsive($tabletSmall, max){
          min-width: 200px;
        }
      }

      &:nth-child(2){
        @include imgRatio(160, 160);
        top: 20%; right: -2%;
        max-width: get-vw(160);

        @include responsive($tabletSmall, max){
          min-width: 120px;
        }
      }

      &:nth-child(3){
        @include imgRatio(155, 280);
        top: 70%; right: 7.5%;
        max-width: get-vw(155);

        @include responsive($tabletSmall, max){
          min-width: 100px;
        }
      }
    }
  }
}
