.ReferenceMarker{
  position: relative;
  border-radius: 14px;
  background: $white;
  padding: 0.5em;
  width: get-vw(350);

  @include responsive($tabletSmall, max){
    width: 200px;
  }

  @include responsive($desk){
    max-width: 350px;
  }

  > .visual__container{
    @include imgRatio(325, 225);
    background: $grey-l;
  }

  > .wrapper{
    @include flex(center, center);
    flex-direction: column;
    padding: 1em 0;

    > address{
      font: 400 italic 14px $main-font;
      @include responsive-font(14);
    }

    > span{
      font: 500 20px $main-font;
      @include responsive-font(24);
      margin: 0.35em 0;
    }

    &, > .wswyg--content{
      text-align: center;
    }

    > .visual__container{
      @include imgRatio(100, 90);
      max-width: 100px;
      margin-top: 1.25em;

      @include responsive($tabletSmall){
        max-width: get-vw(100);
      }

      @include responsive($desk){
        max-width: 100px;
      }

      .visual{
        transform: none;

        img{
          object-fit: contain;
        }
      }
    }
  }
}
