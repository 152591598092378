.PageHeaderWithIcon {
  @include flex(flex-end);
  position: relative;
  padding-top: 10em;
  padding-bottom: 3em;
  color: $white;
  overflow: hidden;
  min-height: 27em;

  @include responsive($tabletSmall, max){
    align-items: flex-start;
    padding-top: 20em;
  }

  &.--show-dropdown {
    @include responsive($tabletSmall, max) {
      .container > .Btn.--dropdown {
        span {
          &:nth-child(1) {
            display: none;
          }
        }
      }
    }
  }

  &:not(.--show-dropdown) {
    @include responsive($tabletSmall, max) {
      .container > .Btn.--dropdown {
        span {
          &:nth-child(2) {
            display: none;
          }
        }
      }

      .container > form{
        display: none;
      }
    }
  }

  &.--faq {
    > .Icon.--user {
      svg > * {
        fill: #5c7828;
      }
    }
  }

  &.--archive-articles {
    .Btn.--dropdown.--white{
      span{
        color: $blue;
      }
    }

    > .Icon.--user {
      svg > * {
        fill: $blue-d1;
      }
    }
  }

  .container {
    z-index: 1;
  }

  .container > .Btn.--dropdown{
    margin-top: 2em;

    @include responsive($tabletSmall){
      display: none;
    }
  }

  .container > form {
    @include flex(flex-start);
    flex-wrap: wrap;
    gap: 1em;
    margin-top: 2em;

    @include responsive($tabletSmall, max){
      flex-direction: column;
    }

    label {
      font: 900 16px $main-font;
      position: relative;
      display: inline-block;
      @include responsive-font(16);

      > input {
        @include cover();
        opacity: 0;
        cursor: pointer;

        &:not(:checked) ~ span {
          opacity: 0.6;
        }

        &:checked ~ span {
          background-size: 100% 1px;
          background-position: 0% 100%;
        }
      }

      > span {
        pointer-events: none;
        display: inline;
        line-height: 1;
        padding-bottom: 0.15em;
        background-image: linear-gradient($white, $white);
        background-position: 100% 100%;
        background-repeat: no-repeat;
        background-size: 0% 1px;
        transition:
          opacity 600ms $ease-in-out-circ,
          background-size 600ms $ease-in-out-circ;
      }
    }
  }

  > .Icon.--user {
    position: absolute;
    bottom: 0;
    right: 5%;
    z-index: 0;

    @include responsive($tabletSmall, max) {
      opacity: 0.5;
    }
  }
}
