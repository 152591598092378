.PageHeaderHome{  
  .container{
    @include flex(center);
    
    @include responsive($tabletSmall, max){
      padding-top: 10em;
      padding-bottom: 5em;
      flex-direction: column;
      gap: 2em;
    }

    @include responsive($tabletSmall){
      min-height: 700px;
      height: 100dvh;
      // max-height: 1200px;
    }

    @include responsive($deskXXL){
      min-height: 950px;
    }
    
    @include responsive($deskXXXL){
      min-height: 1150px;
    }

    > *{
      width: 100%;
    }
  }

  .container > .wrapper{
    @include responsive($tabletSmall){
      max-width: space(13);
      padding-left: space(1);
    }

    h1{
      line-height: 1;
      width: 100%;

      @include responsive($tabletSmall, max){
        font-size: 7.7vw;
      }

      > span.with-keywords{
        @include flex(center);
        gap: 0.25em;
      }

      .keywords{
        position: relative;
        overflow: hidden;
        flex: 1;
  
        strong{
          display: inline-block;
          color: $green;
          border: 1px dashed $green;
          border-radius: 20em;
          top: 0; left: 0;
          padding: 0.1em 0.4em 0.2em;
          white-space: nowrap;
  
          &:not(:first-child){
            position: absolute;
          }
        }
      }
    }

    .wswyg--content{
      margin-top: 2.5em;

      @include responsive($tabletSmall){
        max-width: space(10);
      }
    }
  }

  .container > .gallery{
    position: relative;
    aspect-ratio: 540/564;
    margin-left: auto;

    @include responsive($tabletSmall){
      min-width: space(9);
      max-width: space(9);
    }

    > .item-grid{
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: repeat(8, 1fr);
      grid-gap: 1em;
      position: absolute;
      top: 0; left: 0;
      height: 100%; width: 100%;

      > .visual__container{
        position: relative;
        overflow: hidden;
  
        .visual{        
          img{
            //opacity: 0;
            display: block;
            object-fit: cover;
          }
        }
      }
  
      &[data-step="1"],
      &[data-step="5"]{
        .visual__container{
          &:first-child{
            grid-column: 1/4;
            grid-row: 1/7;
          }

          &:nth-child(2){
            grid-column: 4/6;
            grid-row: 5/9;
          }

          &:nth-child(3) {
            grid-column: 3/4;
            grid-row: 7/9;
          }
        }
      }
    
      &[data-step="2"],
      &[data-step="6"]{
        .visual__container{
          &:first-child{
            grid-column: 2/5;
            grid-row: 3/9;
          }

          &:nth-child(2){
            grid-column: 1/2;
            grid-row: 1/5;
          }

          &:nth-child(3){
            grid-column: 2/3;
            grid-row: 1/3;
          }
        }
      }

      &[data-step="3"],
      &[data-step="7"]{
        .visual__container{
          &:first-child{
            grid-column: 1/4;
            grid-row: 3/9;
          }

          &:nth-child(2){
            grid-column: 4/5;
            grid-row: 1/5;
          }

          &:nth-child(3){
            grid-column: 3/4;
            grid-row: 1/3;
          }
        }
      }

      &[data-step="4"],
      &[data-step="8"]{
        .visual__container{
          &:first-child{
            grid-column: 2/6;
            grid-row: 1/7;
          }

          &:nth-child(2){
            grid-column: 1/2;
            grid-row: 5/9;
          }
      
          &:nth-child(3){
            grid-column: 2/3;
            grid-row: 7/9;
          }
        }
      }
    }  
  }
}
