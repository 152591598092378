.SpecialityCard{
  height: 100%;
  padding: 3em 2em;
  flex-direction: column;
  text-align: center;
  transition: transform 450ms $Power3EaseOut;

  @include noTouchHover() {
    transform: scale(0.95);

    > .ellipse{
      transform: scale(1.15);

      .Icon.--arrow-alt{
        &:first-child{
          transform: translate(2.5em, 0) scale(0.5);
          opacity: 0;
        }

        &:nth-child(2){
          transform: translate(-50%, -50%);
          opacity: 1;
        }
      }
    }
  }

  &, > .ellipse{
    @include flex(center);
  }

  > .head{
    padding-bottom: 2em;

    span{
      font: 500 24px $main-font;
      letter-spacing: -0.025em;
      line-height: 1.1;
      @include responsive-font(40);
      min-height: 1.5em;

      @include responsive($tabletSmall){
        min-height: 2.5em;
      }

      strong{
        color: $green;
      }
    }

    p{
      font: 400 12px $main-font;
      @include responsive-font(12);
      margin-top: 1.25em;
    }
  }

  > .ellipse{
    width: get-vw(64);
    height: get-vw(64);
    border-radius: 50%;
    justify-content: center;
    margin-top: auto;
    position: relative;
    transition: transform 450ms $Power3EaseOut;

    @include responsive($tabletSmall, max){
      min-width: 54px;
      min-height: 54px;
    }

    @include responsive($desk){
      max-width: 64px;
      max-height: 64px;
    }

    .Icon.--arrow-alt{
      transition: opacity 450ms $Power3EaseOut,
                  transform 450ms $Power3EaseOut;

      &:nth-child(2){
        position: absolute;
        top: 50%; left: 50%;
        transform: translate(-2.5em, -50%) scale(0.5);
        opacity: 0;
      }
    }
  }
}
