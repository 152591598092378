.FurnitureTypeCard{
  @include flex(center, center);
  flex-direction: column;
  position: relative;
  overflow: hidden;
  padding: 3em 2em;
  height: 100%;

  @include responsive($tabletSmall){
    padding: 4em 3em;
  }

  @include noTouchHover() {
    transform: scale(0.95);

    > .ellipse{
      transform: scale(1.15);
    }

    > .visual__container{
      transform: translate(50%, 50%) scale(0.9);
    }
  }

  &,
  > .ellipse,
  > .visual__container{
    transition: transform 550ms $Power3EaseOut;
  }

  > .wrapper,
  > .ellipse{
    z-index: 1;
  }

  > .wrapper{
    text-align: center;

    > span{
      font: 500 30px $main-font;
      @include responsive-font(40);
      letter-spacing: -0.025em;
      line-height: 1;

      strong{
        color: $green;
        display: block;
      }
    }

    > p {
      font: 400 14px $main-font;
      @include responsive-font(14);
      line-height: 1.6;
      margin-top: 1.5em;
    }
  }

  > .ellipse{
    margin-top: 8.5em;
    width: get-vw(64);
    height: get-vw(64);

    @include responsive($tabletSmall, max){
      min-width: 64px;
      min-height: 64px;
    }

    @include responsive($desk){
      max-width: 64px;
      max-height: 64px;
    }

    .Icon.--arrow-alt svg > *{
      stroke: $white;
    }
  }

  > .visual__container{
    @include imgRatio(520, 445);
    position: absolute;
    width: get-vw(520);
    max-width: 520px;
    z-index: 0;
    top: 0; right: 0;
    transform: translate(50%, 50%);
    
    @include responsive($tabletSmall, max){
      // opacity: 0.1;
      min-width: 450px;
      transform: translate(50%, 65%);
    }
  }
}
