.ContactBanner{
  position: relative;
  overflow: hidden;

  .container{
    @include flex(center, center);
    flex-direction: column;
    gap: 2em;
    z-index: 1;

    h2, .wswyg--content{
      text-align: center;
    }

    .wswyg--content{
      @include responsive($tabletSmall){
        max-width: space(10);
        margin-left: auto;
        margin-right: auto;
      }
    }
  }

  > .visual__container{
    @include imgRatio(1300, 421);
    width: get-vw(1300);
    position: absolute;
    bottom: 0; left: 50%;
    transform: translate(-50%, 60%);
    z-index: 0;

    @include responsive($deskXL){
      max-width: 1300px;
    }
  }
}
