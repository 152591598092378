.Search{
  z-index: 1;

  body:not(.--animating) &{
    pointer-events: all;
  }

  body:not(.--show-search) &{
    > button{
      &:before,
      &:after{
        opacity: 0;
      }
    }

    > .Form{
      pointer-events: none;
      transform: translate(0, -100%);

      .inner{
        transform: translate(0, 100%);
      }
    }
  }

  body.--show-search &{
    > button{
      .Icon.--search{
        opacity: 0;
      }
    }
  }

  > button{
    body:not(.--show-submenu):not(.--show-search) .Header.--light:not(.--scroll) &{
      border-color: rgba($white, 0.1);
    }

    @include noTouchHover() {
      transform: scale(0.85);
    }

    &:before,
    &:after,
    .Icon.--search{
      transition: opacity 250ms $Power3EaseOut;
    }

    .Icon.--search {
      svg > *{
        stroke: $blue;

        body:not(.--show-submenu):not(.--show-search) .Header.--light:not(.--scroll) &{
          stroke: $white;
        }
      }
    }

    &:before,
    &:after{
      body:not(.--show-submenu):not(.--show-search) .Header.--light:not(.--scroll) &{
        @include responsive($menuBreakpoint){
          background-color: $white;
        }
      }
    }
  }

  > .Form{
    overflow: hidden;
    position: fixed;
    top: 0; left: 0;
    z-index: -1;
    width: 100%;
    padding: 7.5em 2em 3em;
    
    @include responsive($menuBreakpoint){
      padding: 7.5em calc(100vw / 24) 3em;
    }

    &, .inner{
      transition: transform 550ms $Power3EaseOut;
    }

    .inner{
      position: relative;

      .Input > input,
      > button{
        border-bottom: 1px solid $beige-d2;
      }

      .Input{
        > input{
          font: 400 30px $main-font;
          background: none;
          border-radius: 0;
          padding: 0.2em;
          @include responsive-font(40);
        }
      }

      > button{
        @include flex(center, center);
        position: absolute;
        height: 100%;
        top: 0; right: 0;
        background: $white;

        &:before{
          @include content();
          position: absolute;
          top: 0; right: 100%;
          height: 100%;
          width: 100%;
          background: linear-gradient(90deg, rgba($white, 0.5) 0%, $white 100%);
        }
        
        .Icon.--search{
          width: get-vw(40);

          @include responsive($tabletSmall, max){
            min-width: 30px;
          }

          @include responsive($desk){
            max-width: 40px;
          }

          svg > *{
            stroke-width: 1px;
          }
        }
      }
    }
  }
}
