.Engagements{
  .About & {
    padding-bottom: 2.5em;
  }

  .container > .head{
    @include responsive($tabletSmall){
      max-width: space(12);
      margin: 0 auto;
    }

    h2, .wswyg--content{
      text-align: center;
    }

    .wswyg--content{
      margin-top: 2.5em;
    }
  }

  .container > .sections{
    margin-top: 5em;
    overflow: hidden;
    position: relative;
    padding: 2em;

    @include responsive($tabletSmall){
      padding: space(1);
    }

    nav{
      @include responsive($tabletSmall, max){
        margin-bottom: 4em;
      }

      @include responsive($tabletSmall){
        margin-left: space(10);
      }

      ul{
        @include flex(center, center);
        flex-wrap: wrap;
        gap: 0.5em;

        @include responsive($tabletSmall){
          justify-content: flex-start;
        }

        li {
          button{
            font: 900 14px $main-font;
            @include responsive-font(14);
            padding: 0.5em 0.9em;
            transition: color 450ms $Power3EaseOut,
                        background 450ms $Power3EaseOut;
            
            &:not(.--active){
              border: 1px solid rgba(#2E6C7D, 0.2);
            }

            &.--active{
              color: $white;
              background: $green;
            }
          }
        }
      }
    }
  }
}
