.PageHeaderAbout{
  padding-top: 10em;
  padding-bottom: 7em;
  background: $blue-pastel;

  h1{
    text-align: center;

    @include responsive($tabletSmall){
      max-width: space(15);
      margin: 0 auto;
    }
  }

  .visual__container{
    @include imgRatio(1482, 228);
    margin: 3.5em 0;

    .visual{
      transform: scale(1)
    }
  }

  .wswyg--content{
    @include responsive($tabletSmall){
      max-width: space(14);
      margin: 1.5em auto 0;
    }

    p, ul, ol, li, a {
      font: 500 20px $main-font;
      @include responsive-font(24);
    }
  }
}
