.BackButton{
  font: 900 15px $main-font;
  display: inline-flex;
  align-items: center;
  gap: 0.5em;
  margin-bottom: 2em;

  @include responsive($menuBreakpoint){
    display: none;
  }

  .Icon.--arrow-alt{
    transform: scaleX(-1);

    svg > *{
      stroke: $blue-text;
    }
  }
}
