.RelatedFurnituresTypes{
  overflow: hidden;

  h2{
    text-align: center;
    margin-bottom: 1em;

    @include responsive($tabletSmall){
      max-width: space(12);
      margin-left: auto;
      margin-right: auto;
    }
  }

  .swiper{
    .swiper-wrapper{
      @include responsive($tabletSmall){
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 1em;
      }
    }

    .swiper-slide{
      @include responsive($tabletSmall, max){
        max-width: 300px;
      }

      @include responsive($mobile, max){
        max-width: 90%;
      }
    }
  }
}
