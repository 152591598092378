.MainLogo{
  @include imgRatio(167, 53);
  position: relative;
  width: get-vw(167);
  
  @include responsive($tabletSmall, max){
    min-width: 120px;
  }

  @include responsive($desk){
    max-width: 167px;
  }

  .HomeIntro &{
    width: get-vw(300);

    @include responsive($tabletSmall, max){
      min-width: 200px;
    }
    
    @include responsive($tabletSmall){
      max-width: get-vw(300);
    }

    @include responsive($desk){
      max-width: 300px;
    }
  }

  .Footer &{
    max-width: 200px;
    
    @include responsive($tabletSmall){
      max-width: get-vw(210);
    }

    @include responsive($desk){
      max-width: 210px;
    }
  }

  .HomeIntro &,
  .Header &{
    .baseline{
      path{
        opacity: 0;
        transform: translate(0, 5%);
        pointer-events: none;
        transition: transform 250ms $Power3EaseOut,
                    opacity 250ms $Power3EaseOut;

        @for $i from 1 through 25 {
          &:nth-child(#{$i}) {
            transition-delay: $i * 10ms;
          }
        }
      }
    }

    @include noTouchHover() {
      @include responsive($tabletSmall){
        svg {
          .baseline{
            path{
              opacity: 1;
              transform: translate(0, 0);
              pointer-events: all;
            }
          }
        }
      }
    }
  }

  svg {
    @include cover();
    overflow: visible;

    .urbaneo,
    .baseline{
      *{
        fill: $blue;
        transition: transform 350ms $Power3EaseOut;

        body .Header.--light:not(.--scroll) &,
        .HomeIntro &,
        .Footer &{
          fill: $white;
        }

        body.--show-submenu .Header.--light:not(.--scroll) &{
          @include responsive($menuBreakpoint){
            fill: $blue;
          }
        }
      }
    }
  }
}
