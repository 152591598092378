.ServiceCard{
  @include flex(flex-end, center);
  position: relative;
  overflow: hidden;
  padding: 3em;
  aspect-ratio: 1.08;

  @include noTouchHover() {
    transform: scale(0.95);

    > .visual__container .visual{
      transform: scale(1.10);
    }
  }

  &,
  > .visual__container .visual{
    transition: transform 550ms $Power3EaseOut;
  }

  > .wrapper{
    @include flex(center, center);
    flex-direction: column;
    position: relative;
    z-index: 1;

    > .subtitle.tag{
      margin-bottom: 1em;
      background: rgba($white, 0.1);
      backdrop-filter: blur(25px);
    }

    > span{
      font: 500 30px $main-font;
      @include responsive-font(50);
      letter-spacing: -0.025em;
      line-height: 1.2;
      text-align: center;
    }

    > .Btn{
      margin-top: 1.5em;
    }
  }

  > .visual__container{
    z-index: 0;

    &, &:after{
      @include cover();
    }

    &:after{
      @include content();
      background: linear-gradient(180deg, rgba($black, 0) 0%, rgba($black, 0.8) 100%);
    }
  }
}

