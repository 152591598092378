.ReferenceItem{
  @include flex(center);
  gap: 1.5em;
  padding: 2em 0;
  width: 100%;

  &:not(:last-child){
    border-bottom: 1px solid $beige-d1;
  }

  @include responsive($tabletSmall, max){
    align-items: flex-start;
  }

  @include noTouchHover() {
    transform: scale(0.95);

    > .visual__container{
      transform: scale(0.75) rotate(-5deg);
    }

    > .ellipse{
      transform: scale(1.15);
    }
  }

  &.--active{
    > .visual__container{
      transform: scale(0.5) rotate(-3deg);
    }

    > .wrapper{
      > span{
        @include responsive($tabletSmall, max){
          text-decoration: underline;
        }
      }
    }

    > .ellipse{
      border: none;

      .Icon.--arrow-alt{
        svg > *{
          stroke: $white;
        }
      }

      &:after{
        transform: scale(1);
      }
    }
  }

  &, 
  > .visual__container,
  > .ellipse,
  > .ellipse:after{
    transition: transform 550ms $Power3EaseOut;
  }

  > .visual__container{
    @include imgRatio(106, 106);
    width: get-vw(106);
    background: #E3E2DF;

    @include responsive($tabletSmall, max){
      min-width: 106px;
    }

    @include responsive($desk){
      max-width: 106px;
    }

    .visual{
      @include flex(center, center);
      transform: none;
      padding: 0.5em;

      img{
        height: auto;
        max-height: 100%;
        max-width: 100%;
        object-fit: contain;
      }
    }
  }

  > .wrapper{
    flex: 1;

    > span{
      font: 500 20px $main-font;
      @include responsive-font(24);
    }

    > .tags{
      @include flex(center);
      flex-wrap: wrap;
      gap: 0.5em;
      margin-top: 1em;
    }
  }

  > .ellipse{
    position: relative;
    aspect-ratio: 1;
    width: get-vw(50);
    border: 1px solid rgba($blue, 0.1);

    @include responsive($tabletSmall, max){
      min-width: 50px;
      display: none;
    }

    @include responsive($desk){
      max-width: 50px;
    }

    &:after{
      @include content();
      @include cover();
      border-radius: 50%;
      z-index: 0;
      transform: scale(0);
      background: $green;
    }

    .Icon.--arrow-alt{
      z-index: 1;

      svg > *{
        stroke: $blue;
        transition: stroke 550ms $Power3EaseOut;
      }
    }
  }
}
