.Socials{
  ul{
    @include flex(center);

    li{
      a{
        @include flex(center, center);
        height: get-vw(64);
        width: get-vw(64);
        border-radius: 50%;
        border: 2px solid $white;
        background: $blue-d1;
        transition: transform 450ms $Power3EaseOut;

        @include responsive($tabletSmall, max){
          min-height: 50px;
          min-width: 50px;
        }

        @include responsive($desk){
          max-width: 64px;
          max-height: 64px;
        }

        @include noTouchHover() {
          transform: scale(0.85);
        }
      }
    }
  }
}