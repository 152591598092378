.Page{
  padding-top: 10em;
  padding-bottom: 7em;

  .container{
    @include flex(flex-start);

    @include responsive($tabletSmall, max){
      flex-direction: column;
      gap: 2em;
    }

    > *{
      width: 100%;
    }
  }

  .container > h1{
    @include responsive($tabletSmall){
      position: sticky;
      top: 1em;
      max-width: space(10);
    }
  }

  .container > .wswyg--content{
    @include responsive($tabletSmall){
      max-width: space(12);
      margin-left: auto;
    }
  }
}
