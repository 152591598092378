.AllArticles{
  h2{
    .Search &{
      margin-bottom: 0.75em;
    }
  }

  .grid{
    display: grid;
    grid-gap: 5em 1.5em;

    @include responsive($tabletSmall){
      grid-template-columns: repeat(3, 1fr);
    }
  }
}
