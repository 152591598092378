.SpontaneousApplicationModal{
  @include cover(fixed);
  @include flex(flex-start);
  z-index: 100;
  background: rgba($black, 0.6);
  overflow-y: scroll;
  transition: opacity 450ms $ease;
  padding: 5em 0;

  body:not(.--show-spontaneous-application-modal) &{
    opacity: 0;
    pointer-events: none;

    .inner{
      transform: translate(0, 10%);
    }
  }

  .Close{
    position: fixed;
    top: 2em; right: 2em;
    background: $white;
  }
  
  .inner{
    // padding: 3.5em 0 0;
    padding: 3em; 
    width: 100%;
    max-width: 90%;
    margin: 0 auto;
    transition: transform 750ms $Power3EaseOut;
    
    @include responsive($tabletSmall){
      max-width: space(12);
      margin-left: auto;
      margin-right: auto;
    }

    .row,
    .grid {
      > *{
        &:nth-child(5){
          @include responsive($tabletSmall){
            grid-column: span 2;
          }
        }
      }

      .InputFile{
        &:nth-child(7){
          @include responsive($tabletSmall){
            max-width: 57%;
          }
        }

        &:nth-child(8){
          @include responsive($tabletSmall){
            max-width: 96%;
            margin-left: -50%;
          }
        }
      }
    }
  }
}
