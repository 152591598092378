.AllJobOffers{
  .container > .head{
    @include flex(center, center);
    flex-direction: column;
    margin-bottom: 3em;

    h2, 
    .wswyg--content{
      text-align: center;
    }

    .wswyg--content{
      @include responsive($tabletSmall){
        max-width: get-vw(630);
        margin: 1em auto 0;
      }
    }
  }

  .container > .grid{
    display: grid;
    align-items: start;
    grid-gap: 1em;

    @include responsive($mobile){
      grid-template-columns: repeat(2, 1fr);
    }
    
    @include responsive($tabletSmall){
      grid-template-columns: repeat(3, 1fr); 
    }
  }

  .container > p{
    text-align: center;
  }
}
