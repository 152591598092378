.NavButtons{
  @include flex(flex-start);
  gap: 1em;
  z-index: 1;
  pointer-events: all;

  @include responsive($menuBreakpoint, max){
    flex-direction: column;
    width: 100%;
  }

  .Header .Nav &{
    @include responsive($menuBreakpoint){
      display: none;
    }
  }

  .Header .container > & {
    @include responsive($menuBreakpoint, max){
      display: none;
    }
  }

  > li > .Btn{
    &:not(.--outline){
      body:not(.--show-submenu):not(.--show-search) .Header.--light:not(.--scroll) &{
        @include responsive($menuBreakpoint){
          background: $white;
          color: $blue;
        }
      }
    }

    &.--outline{
      body:not(.--show-submenu):not(.--show-search) .Header.--light:not(.--scroll) &{
        @include responsive($menuBreakpoint){
          border-color: $white;
          color: $white;
        }
      }
    }
  }
}
