.NationalPresencePopup{
  padding: 2em;
  transition: opacity 350ms $Power3EaseOut,
              transform 350ms $Power3EaseOut;

  &,
  > address{
    @include flex(flex-start);
    flex-direction: column;
  }

  > .subtitle{
    padding: 0.25em 1em;
    background: $blue-l2;
    border-radius: 20em;
  }

  > span{
    font: 500 22px $main-font;
    @include responsive-font(22);
  }

  > address{
    margin-top: 0.25em;
  }
}