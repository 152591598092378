.EngagementSection{
  @include flex(flex-start);

  @include responsive($tabletSmall, max){
    flex-direction: column;
  }

  &:nth-child(2){
    > .visual__container{ transform: translate(0, 0) rotate(-3deg) scale(1) }

    &:not(.--active){
      > .visual__container{ transform: translate(0, 100%) rotate(3deg) scale(1.25) }
    }
  }

  &:nth-child(3){
    > .visual__container{ transform: translate(0, 0) rotate(3deg) scale(1) }

    &:not(.--active){
      > .visual__container{ transform: translate(0, 100%) rotate(-3deg) scale(1.25) }
    }
  }

  &:nth-child(4){
    > .visual__container{ transform: translate(0, 0) rotate(-6deg) scale(1) }

    &:not(.--active){
      > .visual__container{ transform: translate(0, 100%) rotate(3deg) scale(1.25) }
    }
  }

  &:nth-child(5){
    > .visual__container{ transform: translate(0, 0) rotate(4deg) scale(1) }

    &:not(.--active){
      > .visual__container{ transform: translate(0, 100%) rotate(-2deg) scale(1.25) }
    }
  }

  &:nth-child(6){
    > .visual__container{ transform: translate(0, 0) rotate(-4deg) scale(1) }

    &:not(.--active){
      > .visual__container{ transform: translate(0, 100%) rotate(2deg) scale(1.25) }
    }
  }

  // @for $i from 1 through 10 {
  //   &:nth-child(#{$i}){
  //     > .visual__container{
  //       transform: translate(0, 0) rotate(#{randomNum(-12, 15)}deg) scale(1);
  //     }

  //     &:not(.--active){
  //       > .visual__container{
  //         transform: translate(0, 100%) rotate(#{randomNum(-6, 6)}deg) scale(1.25);
  //         opacity: 0;
  //       }
  //     }
  //   }
  // }

  &:not(.--active){
    position: absolute;
    pointer-events: none;

    > .wrapper,
    > .visual__container{
      transition: none;
    }

    > .visual__container{
      opacity: 0;
    }

    > .wrapper{
      opacity: 0;
      transform: translate(0, 10%);
    }
  }

  > .wrapper,
  > .visual__container{
    transition: opacity 450ms $Power3EaseOut 50ms,
                transform 450ms $Power3EaseOut 50ms;
  }

  > .visual__container{
    @include imgRatio(455, 510);
    max-width: 80%;

    @include responsive($mobile){
      max-width: 350px;
    }

    @include responsive($tabletSmall, max){
      margin: 0 auto 3em;
    }

    @include responsive($tabletSmall){
      max-width: get-vw(455);
    }

    @include responsive($desk){
      max-width: 455px;
    }
  }

  > .wrapper{
    @include responsive($tabletSmall){
      padding-top: 2.5em;
      margin-left: auto;
      max-width: space(10);
    }

    .wswyg--content{
      margin-top: 0.5em;
    }
  }
}
