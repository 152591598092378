.RelatedFurnitures{
  overflow: hidden;

  .container > .head{
    @include flex(center);
    gap: 2em;

    @include responsive($tabletSmall, max){
      flex-direction: column;
    }

    .Btn{
      @include responsive($tabletSmall){
        margin-top: 1em;
        margin-left: auto;
      }
    }
  }

  .container > .swiper{
    margin-top: 3.5em;

    .swiper-wrapper{
      @include responsive($tabletSmall){
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 1em;
      }
    }

    .swiper-slide{
      @include responsive($tabletSmall, max){
        max-width: 400px;
      }
    }
  }
}
