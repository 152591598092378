.BoxCard{
  @include flex(center, center);
  flex-direction: column;
  padding: 4em 3.8em;
  height: 100%;
  text-align: center;
  position: relative;

  @include noTouchHover() {
    transform: scale(0.95);

    > .visual__container{
      transform: scale(0.9) rotate(-4deg);
    }
  }

  &[data-grid]{
    &:after{
      @include content();
      @include cover();
      background-image: url("data:image/svg+xml,%3Csvg width='90' height='90' viewBox='0 0 90 90' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='0.5' y='0.5' width='89' height='89' stroke='white' stroke-dasharray='2 2'/%3E%3C/svg%3E%0A");
      background-repeat: repeat;
      opacity: 0.15;
      z-index: 0;
    }
  }

  &, 
  > .visual__container{
    transition: transform 550ms $Power3EaseOut;
  }

  > .visual__container{
    @include imgRatio(200, 200);
    max-width: 120px;

    @include responsive($tabletSmall){
      max-width: get-vw(120);
    }

    @include responsive($desk){
      max-width: 120px;
    }
    
    // max-width: 180px;
    
    // @include responsive($tabletSmall){
    //   max-width: get-vw(180);
    // }

    // @include responsive($desk){
    //   max-width: 180px;
    // }

    .visual{
      @include flex(center, center);
      transform: none;

      img{
        height: auto;
        width: auto;
        max-width: 100%;
        object-fit: none;
      }
    }
  }

  > span{
    font: 500 28px $main-font;
    letter-spacing: -0.025em;
    line-height: 1.3;
    @include responsive-font(32);
  }
  
  > p{
    font: 500 16px $main-font;
    @include responsive-font(18);
    line-height: 1.2;
    margin-top: 1.25em;
  }

  > .Btn{
    margin-top: 2em;

    .Icon.--arrow-alt-fake{
      @include imgRatio(16, 16);
      width: get-vw(16);
      margin-top: 0.15em;

      @include responsive($tabletSmall, max){
        min-width: 16px;
      }

      @include responsive($desk){
        max-width: 16px;
      }
    }
  }
}
