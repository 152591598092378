.Brand {
  position: relative;
  z-index: 10;
  pointer-events: all;
  transition: opacity 350ms $Power3EaseOut;

  body.--show-menu &{
    opacity: 0.2;
    pointer-events: none;
  }

  .Header &{
    @include responsive($menuBreakpoint, max){
      margin-right: auto;
    }
  }
}
