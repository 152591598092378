.JobApply{
  border-top: 1px dashed $beige-d2;

  .head{
    text-align: center;

    p{
      margin-top: 1em;

      @include responsive($tabletSmall){
        max-width: space(10);
        margin-left: auto;
        margin-right: auto;
      }
    }
  }

  .inner{
    margin-top: 5em;

    @include responsive($tabletSmall){
      max-width: space(12);
      margin-left: auto;
      margin-right: auto;
    }

    .Form{
      .InputFile{
        &:nth-child(7){
          @include responsive($tabletSmall){
            max-width: 50%;
          }
        }

        &:nth-child(8){
          @include responsive($tabletSmall){
            max-width: 83%;
            margin-left: -50%;
          }
        }
      }
    }
  }
}
