.RelatedServices{
  overflow: hidden;

  .container > .head{
    @include flex(center);
    gap: 2em;
    margin-bottom: 3em;

    @include responsive($tabletSmall, max){
      flex-direction: column;
    }

    .Btn{
      @include responsive($tabletSmall){
        margin-top: 0.5em;
        margin-left: auto;
      }
    }
  }

  .container > .swiper{
    .swiper-wrapper{
      @include responsive($tabletSmall){
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 1em;
      }
    }
  }
}
