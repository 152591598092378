.fixed-navigation{
  position: fixed;
  bottom: 1em; left: 50%;
  transform: translate(-50%, 0);
  z-index: 100;
  padding: 0.5em;
  white-space: nowrap;
  transition: opacity 350ms $Power3EaseOut,
              transform 350ms $Power3EaseOut;
    
  &:not(.--active){
    opacity: 0;
    transform: translate(-50%, 25%) scale(0.75);
    pointer-events: none;
  }

  .About &{
    @include responsive($tabletSmall, max){
      display: none;
    }
  }
    
  ul{
    @include flex();
    gap: 0.5em;

    li > a{
      font: 900 14px $main-font;
      @include responsive-font(14);
      border-radius: 6px;
      padding: 0.8em;
      text-decoration: none;
      display: block;
      transition: color 350ms $Power3EaseOut,
                  background 350ms $Power3EaseOut;
        
      &:not(.--active){
        border: 1px solid rgba($white, 0.2);
        color: $white;
      }
        
      &.--active{
        border: 1px solid rgba($white, 1);
        background: $white;
        color: $blue;
      }
    }
  }
}