.PageHeaderWithGallery{
  position: relative;
  padding-top: 12em;
  padding-bottom: 5em;
  overflow: hidden;

  .container {
    z-index: 1;

    > .wrapper{
      @include flex(center, center);
      flex-direction: column;
      text-align: center;

      @include responsive($tabletSmall){
        max-width: space(14);
        margin: 0 auto;
      }

      > h1{
        line-height: 1;
      }

      > p{
        margin: 1.5em auto 0;

        @include responsive($tabletSmall){
          max-width: 80%;
        }

        @include responsive($desk){
          max-width: 700px;
        }
      }
    }
  }

  .gallery{
    @include responsive($tabletSmall, max){
      opacity: 0.1;
    }

    > .visual__container{
      @include imgRatio(160, 160);
      max-width: get-vw(160);
      position: absolute;

      @include responsive($tabletSmall, max){
        min-width: 120px;
      }

      &:first-child{
        top: 50%; left: 5%;

        .ArchiveReferences &{
          top: 35%; left: 10%;
        }
      }

      &:nth-child(2){
        top: 20%; right: 8%;

        .ArchiveReferences &{
          top: 45%; right: 7%;
        }
      }

      &:nth-child(3){
        @include imgRatio(155, 280);
        width: get-vw(155);
        top: 35%; right: 2%;

        .ArchiveReferences &{
          top: 55%; left: 2%;

          @include responsive($tabletSmall){
            top: 30%; 
          }
        }
      }
    }
  }
}
