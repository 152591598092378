.Link {
  position: relative;
  display: inline;
  transition: color 350ms $Power3EaseOut;

  &.--icon{
    @include flex();
    gap: 0.25em;
  }

  &.--green{
    color: $green;

    > span{
      background-image: linear-gradient($green, $green);
    }

    > .Icon.--chevron{
      svg > *{
        stroke: $green;
      }
    }
  }

  &.--blue{
    color: $blue;

    > span{
      background-image: linear-gradient($blue, $blue);
    }
  }

  &.--white{
    color: $white;

    > span{
      background-image: linear-gradient($white, $white);
    }
  }

  &[download]{
    display: inline-flex;
    align-items: flex-start;
    color: $blue;

    > span{
      font: 600 16px $main-font;
      margin-left: 0.75em;
      padding-top: 0.35em;
      background-image: linear-gradient($blue, $blue);

      @include responsive($tabletSmall){
        @include font-vw(16);
      }
    }

    @include noTouchHover() {
      > .Icon.--download{
        transform: scale(0.85);
      }
    }
  }

  > span{
    display: inline;
    line-height: 1;
    background-image: linear-gradient($black, $black);
    background-position: 100% 100%;
    background-repeat: no-repeat;
    background-size: 0% 1px;
    transition: background-color 350ms $Power3EaseOut,
                background-size 600ms $ease-in-out-circ;
  }

  @include noTouchHover() {
    > span{
      background-size: 100% 1px;
      background-position: 0% 100%;
    }
  }
}