.Numbers {
  overflow: hidden;

  .container > h2 {
    text-align: center;
    margin-bottom: 1em;
  }

  .container > .swiper{
    .swiper-wrapper{
      cursor: initial;
    }
  }
}
