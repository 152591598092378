$tarteaucitron-bgcolor: $base;
$allowed-color: #20b55f;
$denied-color: #d44545;

#tarteaucitronRoot {

  &.tarteaucitronBeforeVisible {
    #tarteaucitronAlertBig {
      display: flex!important;
      align-items: center;
      flex-wrap: wrap;
      
      @include responsive($tabletSmall, max){
        justify-content: center;
      }
    }
  }

  #tarteaucitronBack {
    background: $black!important;
    opacity: 0.9!important;
  }

  #tarteaucitronAlertBig {
    background: $blue-d1 !important;
    box-sizing: border-box !important;
    bottom: 1em;
    width: 100% !important;
    margin: 0 !important;
    max-width: 90%;
    padding: 2em !important;
    border-radius: 10px;
    gap: 0.5em;
    left: 50% !important;
    transform: translate(-50%, 0);
    right: auto !important;

    @include responsive(520px) {
      max-width: 380px;
    }

    @include responsive($tabletSmall, max){
      align-items: center;
      justify-content: center;
    }
    
    @include responsive($tabletSmall){
      max-width: space(15);
      padding: 1em !important;
    }

    @include responsive($deskXL){
      max-width: 950px;
    }

    > span{
      font: 500 12px $main-font;
      flex: 1;

      @include responsive($tabletSmall, max){
        flex: 1 0 100%;
        text-align: center;
        margin-bottom: 1em;
      }

      @include responsive($tabletSmall){
        @include font-vw(12);
      }

      @include responsive($deskXL){
        font-size: 12px;
      }
    }

    > button {
      all: unset;
      cursor: pointer;
      box-sizing: border-box;
      font-weight: 900;
      background: $blue;
      color: $white;
      border-radius: 5px;
      padding: 0.5em 1em;
      font-size: 0.7em !important;
      transition: background 350ms $CircEaseInOut,
                  opacity 350ms $CircEaseInOut;
    
      @include responsive(520px) {
        width: auto;
        margin-top: 0!important;
        white-space: nowrap;
      }

      @include responsive($tabletSmall){
        font-size: 0.78125vw !important;
      }

      @include responsive($deskXL){
        font-size: 12px !important;
      }

      &:hover {
        background: darken($blue, 8.5%) !important;
      }

      &#tarteaucitronCloseAlert {
        opacity: 0.3;
        transition: opacity 350ms $CircEaseInOut;

        &:hover {
          opacity: 1;
        }
      }

      span {
        display:none!important;
      }
    }
  }

  #tarteaucitronClosePanel {
    background: $tarteaucitron-bgcolor!important;
    border-color: $tarteaucitron-bgcolor!important;
  }

  #tarteaucitronServices {
    box-shadow: none!important;

    .tarteaucitronMainLine,
    .tarteaucitronTitle button {
      background: $tarteaucitron-bgcolor!important;
      border-color: $tarteaucitron-bgcolor!important;
    }

    .tarteaucitronAllow, .tarteaucitronDeny {
      margin: 0!important;
      background: $white !important;
      font-size: 9px!important;
      letter-spacing: 0.1em;
      font-weight: bold;
      margin-left: 10px!important;
      padding: 10px 20px!important;
      color: $tarteaucitron-bgcolor!important;
      text-transform: uppercase;

      &:hover {
        opacity: 1;
      }

      span {
        display: none!important;
      }
    }

    ul .tarteaucitronLine.tarteaucitronIsAllowed { border-color: $allowed-color!important; }
    ul .tarteaucitronLine.tarteaucitronIsDenied { border-color: $denied-color!important; }

    #tarteaucitronInfo {
      background: 0!important;
    }
  }
}
