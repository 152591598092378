.PageHeader{
  padding-top: 10em;

  .Gutenberg &{
    padding-top: 0;
  }

  .container{
    @include flex(center, center);
    flex-direction: column;
    gap: 1em;

    h1, .wswyg--content{
      text-align: center;
      margin-bottom: 0.35em;
    }

    .wswyg--content{
      @include responsive($tabletSmall){
        max-width: space(12);
        margin: 0 auto;
      }
    }

    .Btn{
      margin-top: 1.5em;
    }
  }
}
