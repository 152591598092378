.NavSubmenu[data-services]{
  @include responsive($menuBreakpoint){
    padding-bottom: 1.5em;
  }

  .swiper{
    .swiper-wrapper{
      @include responsive($menuBreakpoint, max){
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 1em;
      }
    }

    .swiper-slide{
      // max-width: 70%;

      @include responsive($menuBreakpoint){
        max-width: get-vw(205);
      }

      @include responsive($desk){
        max-width: 205px;
      }
    }
  }

  .all-services{
    @include flex(center, center);
    margin-top: 2em;
  }
}