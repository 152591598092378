.InputFile{
  @include flex(flex-start);
  flex-direction: column;
  position: relative;

  .upload__file > div, 
  .res{
    font: 900 14px $main-font;
    @include responsive-font(14);
  }

  .upload-file{
    @include flex(center);
    cursor: pointer;
    position: relative;
    padding: 1em;
    width: 100%;
    border: 1px solid $green;
    transition: transform 450ms $Power3EaseOut;

    @include noTouchHover() {
      transform: scale(0.95);
    }

    &.--error{
      border-color: $error-color;

      > div{
        color: $error-color;
      }
    }

    > div{
      font-weight: 900;
      pointer-events: none;
      position: relative;
      z-index: 0;
    }

    .Icon.--upload{
      margin-left: 0.5em;
    }

    input{
      @include cover();
      opacity: 0;
      z-index: 1;
      
      &, &::-webkit-file-upload-button{
        cursor: pointer;
      }
    }
  }

  .res{
    display: none;
    pointer-events: none;
    margin-top: 1em;
    width: 100%;
    
    &.--active{
      @include flex(center);
      gap: 1em;
    }
    
    .res-text{
      flex: 1;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;  
    }

    button{
      cursor: pointer;
      pointer-events: all;
    }
  }
}
