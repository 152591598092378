.Breadcrumb{
  ul{
    @include flex(center);

    @include responsive($tabletSmall, max){
      align-items: flex-start;
      flex-direction: column;
    }

    li {
      &:not(:first-child) {
        &:before{
          @include responsive($tabletSmall, max){
            content: '';
            display: inline-block;
            height: 16px;
            width: 16px;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='icon icon-tabler icon-tabler-corner-down-right' width='16' height='16' viewBox='0 0 24 24' stroke-width='1.5' stroke='%232E6C7D' fill='none' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath stroke='none' d='M0 0h24v24H0z' fill='none'/%3E%3Cpath d='M6 6v6a3 3 0 0 0 3 3h10l-4 -4m0 8l4 -4' /%3E%3C/svg%3E");
            background-size: 100%;
            background-repeat: no-repeat;
            transform: translate(0, -0.2em);
          }
        }
      }

      &:not(:last-child){
        &:after{
          @include responsive($tabletSmall){
            content: '—';
            display: inline-block;
            padding: 0 0.5em;
          }
        }
      }

      &:last-child{
        a, span{
          font-weight: 1000;
        }
      }

      > a, 
      > span{
        font: 500 12px $main-font;
        color: $blue;
        @include responsive-font(12);

        @include responsive($tabletSmall, max){
          display: inline-block;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          max-width: 20em;
        }
      }
    }
  }
}
