.NationalPresence{
  background: #F6F8F9;
  
  .Contact &{
    border-top: 1px dashed $beige-d2;
  }

  .container{
    @include flex(center);

    @include responsive($tabletSmall, max){
      align-items: flex-start;
      flex-direction: column;
      gap: 3em;
    }
  }

  .container > .head{
    @include responsive($tabletSmall){
      max-width: space(8);
    }

    h2{
      strong{
        color: $green;
      }
    }

    .wswyg--content{
      margin-top: 2em;
    }
  }

  .container > .popup-mobile{
    @include responsive($tablet){
      display: none;
    }
  }
}
