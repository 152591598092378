.ArticleCard{
  position: relative; 

  @include noTouchHover() {
    transform: scale(0.95);

    > .head > .visual__container .visual{
      transform: scale(1.15);
    }
  }

  &.--big{
    @include responsive($tabletSmall){
      grid-column: span 2;
    }

    > .head{
      @include responsive($tabletSmall){
        margin-bottom: 0;
      }

      &, > .visual__container{
        @include responsive($tabletSmall){
          height: 100%;
        }
      }

      > .Categories{
        @include responsive($tabletSmall){
          padding: 2em;
        }
      }

      > .visual__container{
        @include responsive($tabletSmall){
          @include imgRatio(872, 408);
        }

        &:after{
          @include responsive($tabletSmall){
            @include content();
            @include cover();
            opacity: 0.7;
            background: linear-gradient(180deg, rgba($black, 0.7) 20%, $black 80%);
          }
        }
      }
    }

    > .wrapper{
      @include responsive($tabletSmall){
        position: absolute;
        bottom: 0; left: 0;
        width: 100%;
        padding: 2em;
      }

      > span,
      > p{
        @include responsive($tabletSmall){
          color: $white;
        }
      }
    }
  }
  
  &,
  > .head > .visual__container .visual{
    transition: transform 550ms $Power3EaseOut;
  }
  
  > .head{
    position: relative;
    margin-bottom: 1em;
    
    > .Categories{
      position: absolute;
      top: 0; left: 0;
      padding: 1em;
    }

    > .visual__container{
      @include imgRatio(424, 264);
      background: $white;

      .visual{
        transform: none;
      }
    }
  }

  > .wrapper{
    > span{
      font: 500 22px $main-font;
      letter-spacing: -0.025em;
      color: $blue;
      @include responsive-font(28);
      line-height: 1.2;
    }
  
    > p {
      margin-top: 0.5em;
    }
  }
}
