.Textarea{
  @include responsive($tabletSmall){
    grid-column: span 2;
  }

  textarea{
    width: 100%;
    padding: 1em;
    min-height: 12.5em;
    background: $beige;
    border-radius: 10px;

    &::placeholder{
      color: $blue;
      opacity: 0.5;
    }
  }
}
