.NavSubmenu{
  overflow: hidden;
  position: fixed;
  width: 100%;
  top: 0; 
  
  @include responsive($menuBreakpoint, max){
    right: 0;
    padding: 5em 2em;
    height: 100%;
    max-width: 450px;
    z-index: 11;
    overflow-y: scroll;
  }

  @include responsive($mobile, max){
    max-width: 80%;
  }

  @include responsive($menuBreakpoint){
    left: 0;
    padding: 7.5em calc(100vw / 24) 3em;
    z-index: -1;
  }

  li.--submenu:not(.--active) &{
    pointer-events: none;

    @include responsive($menuBreakpoint, max){
      transform: translate(105%, 0);
    }
    
    @include responsive($menuBreakpoint){
      transform: translate(0, -105%);
    }
    
    > .inner{
      @include responsive($menuBreakpoint, max){
        transform: translate(-100%, 0);
      }
      
      @include responsive($menuBreakpoint){
        transform: translate(0, 100%);
      }
    }
  }

  &,
  > .inner{
    transition: transform 550ms $Power3EaseOut;
  }
}
