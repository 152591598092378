.Values{
  overflow: hidden;
  padding-bottom: 0;

  .container > h2{
    @include responsive($tabletSmall, max){
      text-align: center;
    }
  }

  .container > .swiper{
    margin-top: 3em;

    .swiper-slide{
      max-width: 90%;

      @include responsive($mobile){
        max-width: 400px;
      }

      @include responsive($tabletSmall){
        max-width: get-vw(425);
      }

      @include responsive($desk){
        max-width: 425px;
      }
    }

    .swiper-navigation{
      @include responsive($tabletSmall, max){
        margin-top: 2em;
        order: 2;
        justify-content: center;
      }

      @include responsive($tabletSmall){
        position: absolute;
        bottom: 108%; right: 0;
      }
    }
  }

  .container + .visual__container{
    margin-top: 5em;
  }

  > .visual__container{
    @include imgRatio(1440, 620);
  }
}
