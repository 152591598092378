.Hiring{
  .container > .inner{
    @include flex();
    overflow: hidden;

    @include responsive($tabletSmall, max){
      flex-direction: column;
    }

    > .wrapper{
      @include flex(center, center);
      flex-direction: column;
      padding: 3em;
      
      @include responsive($tabletSmall){
        padding: 4em 8em;
        max-width: space(12);
      }

      h2, 
      .wswyg--content{
        text-align: center;
      }

      .wswyg--content,
      .Btn{
        margin-top: 2em;
      }
      
      .wswyg--content{
        filter: opacity(0.7);
      }
    }

    > .visual__container{
      @include imgRatio(600, 467);

      @include responsive($tabletSmall){
        max-width: space(10);
      }
    }
  }
}
