.PageHeaderSingle{
  padding-top: 10em;
  padding-bottom: 5em;
  
  .Article &{
    padding-bottom: 7.5em;
    
    @include responsive($tabletSmall){
      padding-bottom: 20em;
    }
  }

  .container > h1{
    margin: 0.35em 0 0;
    width: 100%;

    @include responsive($tabletSmall){
      max-width: space(16);
    }
  }

  .container > .wrapper{
    margin-top: 2em;
    gap: 2em;

    @include responsive($tabletSmall, max){
      flex-direction: column;
    }

    &, > .infos{
      @include flex(flex-start);
      flex-wrap: wrap;
    }

    @include responsive($tabletSmall){
      justify-content: space-between;
    }

    > .infos{
      align-items: center;
      gap: 1em;

      time{
        font: 900 14px $main-font;
        color: $grey;
        @include responsive-font(14);
      }
    }
  }
}
