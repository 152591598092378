.Nav {
  @include flex(center);
  pointer-events: all;
  z-index: 1;
  
  @include responsive($menuBreakpoint, max){
    transition: transform 550ms $Power3EaseOut;
  }

  body.--animating &{
    pointer-events: none;
  }

  body.--show-search &{
    z-index: 2;
  }
  
  @include responsive($menuBreakpoint, max) {
    @include flex(flex-start);
    flex-direction: column;
    position: fixed;
    top: 0; right: 0;
    width: 100%; height: 100%;
    max-width: 450px;
    background: $beige-d1;
    color: $white;
    overflow-y: scroll;
    padding: 10em 2em 5em;
    z-index: 10;
  }

  @include responsive($mobile, max){
    max-width: 80%;
  }

  @include responsive($menuBreakpoint){
    margin-left: auto;
  }

  body:not(.--show-menu) & {
    @include responsive($menuBreakpoint, max) {
      transform: translate(100%, 0);  
    }
  }
  
  &, > ul{
    gap: 2em;
  }

  > ul{
    @include flex(flex-start);

    @include responsive($menuBreakpoint, max){
      flex-direction: column;
      gap: 1em;
      width: 100%;
    }

    @include responsive($menuBreakpoint){
      align-items: center;
      justify-content: center;
    }

    &:last-child{
      @include responsive($menuBreakpoint){
        gap: 1em;
      }
    }

    > li{
      position: relative;

      &.--submenu.--active{
        > .Link{
          > .Icon.--chevron{
            @include responsive($menuBreakpoint){
              transform: scaleY(-1);
            }
          }
        }
      }

      &.--active{
        > .Link{
          color: $green;

          > span{
            background-image: linear-gradient($green, $green);
            background-size: 100% 1px;
            background-position: 0% 100%;
          }

          body:not(.--show-submenu):not(.--show-search) .Header.--light:not(.--scroll) &{
            @include responsive($menuBreakpoint){
              color: $white;
  
              > span{
                background-image: linear-gradient($white, $white);
              }
            }
          }

          .Icon.--chevron svg > *{
            stroke: $green;
          }
        }
      }

      > .Link{
        font: 900 20px $main-font;
        @include responsive-font(15);
        color: $blue;
        display: inline-flex;
        align-items: center;
        gap: 0.35em;

        body:not(.--show-submenu):not(.--show-search) .Header.--light:not(.--scroll) &{
          @include responsive($menuBreakpoint){
            color: $white;

            > span{
              background-image: linear-gradient($white, $white);
            }
          }
        }

        > span{
          line-height: 1.5;
          background-image: linear-gradient($blue, $blue);
        }

        > .Icon.--chevron{
          width: get-vw(10);
          margin-top: 0.35em;
          transition: transform 300ms $Power3EaseOut;

          @include responsive($menuBreakpoint, max){
            min-width: 10px;
            transform: rotate(-90deg);
          }

          @include responsive($desk){
            max-width: 10px;
          }

          svg > *{
            stroke: $blue;
            transition: transform 350ms $Power3EaseOut;

            body:not(.--show-submenu):not(.--show-search) .Header.--light:not(.--scroll) &{
              @include responsive($menuBreakpoint){
                stroke: $white;
              }
            }
          }
        }
      }
    }
  }
}
