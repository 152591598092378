.BannerColorContent{
  padding-top: 0;

  .ArchiveJobs &,
  .About &{
    background: $beige;
  }

  // &[data-theme="blue"],
  // &[data-theme="green"]{
  //   .container > .wrapper .inner{
  //     h2{  color: $white; }
  //     .wswyg--content{ color: $beige; }
  //   }
  // }

  &[data-theme="blue"]{
    .container > .wrapper{
      background: $blue;
    }
  }

  // &[data-theme="green"]{}

  &[data-theme="beige"]{
    .container > .wrapper {
      background: $beige;

      .inner{
        h2{  color: $blue; }
        .wswyg--content{ color: $blue-text; }
      }
    }
  }

  .About &{
    background: $white;
    padding-bottom: 2.5em;
  }

  .container {
    > .wrapper {
      background: $green;

      .inner{
        @include flex(center, center);
        flex-direction: column;
        padding: 3em;
      
        @include responsive($tabletSmall){
          max-width: space(10);
          margin: 0 auto;
          padding: 5em 0;
        }

        h2, .wswyg--content{
          text-align: center;
        }

        h2{
          color: $white;
        }

        .wswyg--content{
          margin-top: 1.5em;
          color: $beige;
        }

        .Btn{
          margin-top: 2em;
        }
      }
    }
  }
}
