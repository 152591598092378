.Icon {
  position: relative;

  svg {
    @include cover();
  }

  &.--facebook,
  &.--x,
  &.--linkedin,
  &.--instagram{
    width: get-vw(14);

    @include responsive($tabletSmall, max){
      min-width: 14px;
    }

    @include responsive($desk){
      max-width: 14px;
    }

    svg > *{
      .Sharing &{
        fill: $blue;
      }

      .Socials &{
        fill: $white;
      }
    }
  }

  &.--instagram,
  &.--facebook{
    @include imgRatio(14, 14);
  }

  &.--x{
    @include imgRatio(14, 12);
  }

  &.--linkedin{
    @include imgRatio(16, 16);
  }

  &.--play{
    @include imgRatio(17, 20);
    width: get-vw(17);

    @include responsive($tabletSmall, max){
      min-width: 17px;      
    }

    @include responsive($desk){
      max-width: 17px;
    }
  }
  
  &.--user{
    @include imgRatio(450, 510);
    width: get-vw(450);

    @include responsive($tabletSmall, max){
      min-width: 250px;
    }

    @include responsive($desk){
      max-width: 20vw;
    }
  }

  &.--chevron{
    @include imgRatio(12, 6);
    width: get-vw(12);

    @include responsive($tabletSmall, max){
      min-width: 12px;
    }

    @include responsive($desk){
      max-width: 12px;
    }

    svg > *{
      stroke: $blue-d1;
    }
  }

  &.--arrow{
    @include imgRatio(12, 12);
    width: get-vw(12);

    @include responsive($tabletSmall, max){
      min-width: 12px;
    }

    @include responsive($desk){
      max-width: 12px;
    }

    .PageHeaderFurniture nav.links &,
    .swiper-navigation &{
      width: get-vw(16);

      @include responsive($tabletSmall, max){
        min-width: 16px;
      }

      @include responsive($desk){
        max-width: 16px;
      }
    }

    svg > *{
      stroke: $white;
    }
  }

  &.--arrow-alt{
    @include imgRatio(16, 16);
    width: get-vw(16);

    @include responsive($tabletSmall, max){
      min-width: 16px;
    }

    @include responsive($desk){
      max-width: 16px;
    }

    svg > *{
      stroke: $white;
    }
  }

  &.--upload{
    @include imgRatio(16, 16);
    width: get-vw(16);

    @include responsive($tabletSmall, max){
      min-width: 16px;
    }

    @include responsive($desk){
      max-width: 16px;
    }

    svg > *{
      stroke: $green;
    }
  }

  &.--trash{
    @include imgRatio(16, 16);
    width: get-vw(16);

    @include responsive($tabletSmall, max){
      min-width: 16px;
    }

    @include responsive($desk){
      max-width: 16px;
    }

    svg > *:not(:first-child){
      fill: $error-color;
    }
  }

  &.--paper{
    @include imgRatio(16, 16);
    width: get-vw(16);

    @include responsive($tabletSmall, max){
      min-width: 16px;
    }

    @include responsive($desk){
      max-width: 16px;
    }

    svg > *{
      stroke: $blue;
    }
  }

  &.--tick{
    @include imgRatio(10, 8);
    width: get-vw(10);

    @include responsive($tabletSmall, max){
      min-width: 10px;
    }

    @include responsive($desk){
      max-width: 10px;
    }

    svg > *{
      stroke: $white;
    }
  }

  &.--filter{
    @include imgRatio(12, 12);
    width: get-vw(12);

    @include responsive($tabletSmall, max){
      min-width: 12px;
    }

    @include responsive($desk){
      max-width: 12px;
    }

    svg > *{
      stroke: $white;
    }
  }

  &.--search{
    @include imgRatio(16, 16);
    width: get-vw(16);

    @include responsive($tabletSmall, max){
      min-width: 16px;
    }

    @include responsive($desk){
      max-width: 16px;
    }
  }
}
