.FiltersModal {
  @include cover(fixed);
  @include flex(flex-start, flex-end);
  z-index: 100;
  background: rgba($black, 0.6);
  overflow-y: scroll;
  transition: opacity 450ms $ease 0ms;

  .inner {
    @include flex();
    flex-direction: column;
    padding: 3.5em 0 0;
    height: 100%;
    width: 100%;
    transition: transform 750ms $Power3EaseInOut 250ms;
    max-width: 90%;

    @include responsive($tabletSmall) {
      max-width: space(10);
    }

    > h3 {
      padding: 0 1.25em;
    }

    > form,
    .grid{
      @include flex();
      flex-direction: column;
      flex: 1;
    }

    .grid {
      padding: 0 3em 3em;
    }

    .category {
      padding: 2em 0;

      &:not(:last-child) {
        border-bottom: 1px dashed rgba($blue, 0.2);
      }

      .subtitle {
        margin-bottom: 1em;
      }

      .items {
        @include flex(center);
        flex-wrap: wrap;
        gap: 1em;
      }
    }

    .bottom {
      @include flex(center);
      padding: 2em 3em;

      @include responsive($tabletSmall, max) {
        flex-direction: column-reverse;
      }

      .close-modal {
        font: 900 14px $main-font;
        @include responsive-font(14);

        @include responsive($tabletSmall, max) {
          margin-top: 2em;
        }
      }

      .wrapper {
        @include flex(center, center);
        gap: 1em;

        @include responsive($tabletSmall) {
          margin-left: auto;
        }
      }
    }
  }
}

// Furnitures Modal
.FiltersFurnituresModal {
  body:not(.--show-furnitures-modal) & {
    opacity: 0;
    pointer-events: none;
    transition-delay: 350ms;

    .inner {
      transform: translate(100%, 0);
      transition-delay: 0ms;
    }
  }
}

// References Modal
.FiltersReferencesModal {
  body:not(.--show-references-modal) & {
    opacity: 0;
    pointer-events: none;
    transition-delay: 350ms;

    .inner {
      transform: translate(100%, 0);
      transition-delay: 0ms;
    }
  }

  .inner{
    .grid {
      padding: 2em 3em 3em;

      nav {
        ul {
          @include flex(center);
          flex-wrap: wrap;
          gap: 1em;
        }

        label {
          font: 900 18px $main-font;
          @include responsive-font(18);
          position: relative;
          cursor: pointer;

          > input {
            @include cover();
            opacity: 0;

            &:checked ~ span {
              color: $green;
              background-image: linear-gradient($green, $green);
              background-size: 100% 1px;
              background-position: 0% 100%;
            }

            &:not(:checked) ~ span {
              color: $blue;
              background-image: linear-gradient($blue, $blue);
            }
          }
        }
      }

      .Tabs{
        margin-top: 2em;
        position: relative;

        .Tab{
          @include flex();
          flex-wrap: wrap;
          gap: 1em;

          &:not(.--active){
            position: absolute;
            opacity: 0;
            pointer-events: none;
          }
        }
      }
    }
  }
}
