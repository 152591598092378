@import url("../../node_modules/mapbox-gl/dist/mapbox-gl.css");

.ReferencesMap{
  padding-top: 0;
  background-color: $white;

  // Form
  .MapForm{
    background-color: $beige;

    &.--show-dropdown {
      @include responsive($tabletSmall, max) {
        .inner > .Btn.--dropdown {
          span {
            &:nth-child(1) {
              display: none;
            }
          }
        }
      }
    }

    &:not(.--show-dropdown) {
      @include responsive($tabletSmall, max) {
        .inner > .Btn.--dropdown {
          span {
            &:nth-child(2) {
              display: none;
            }
          }
        }

        .inner > .checkboxes{
          display: none;
        }
      }
    }

    &, .inner{
      padding-top: 1.5em;
      padding-bottom: 1.5em;
    }
    
    .inner{
      @include flex(flex-start);
      border-top: 1px solid $beige-d1;

      @include responsive($tabletSmall, max){
        flex-direction: column;
      }

      > .Btn.--dropdown{
        margin-top: 2em;

        @include responsive($tabletSmall){
          display: none;
        }
      }

      > .checkboxes{
        @include flex(center);
        flex-wrap: wrap;
        gap: 1em 2em;
        margin: 2em 0 1em;

        @include responsive($tabletSmall, max){
          flex-direction: column;
          align-items: flex-start;
        }

        label{
          font: 900 18px $main-font;
          @include responsive-font(18);
          position: relative;
          cursor: pointer;

          > input{
            @include cover();
            opacity: 0;

            &:checked ~ span {
              color: $green;
              background-image: linear-gradient($green, $green);
              background-size: 100% 1px;
              background-position: 0% 100%;
            }

            &:not(:checked) ~ span{
              color: $blue;
              background-image: linear-gradient($blue, $blue);
            }
          }
        }
      }

      > .Btn:not(.--dropdown){
        gap: 1.5em;

        @include responsive($tabletSmall, max){
          margin-top: 1em;
        }

        @include responsive($tabletSmall){
          margin-left: auto;
        }
      }
    }
  }

  // Map
  .MapContainer{
    @include flex(center, center);
    flex-direction: column;

    > .inner{
      @include flex(flex-start);
      position: relative;

      @include responsive($tabletSmall, max){
        margin-top: 2em;
        flex-direction: column-reverse;
      }

      &, > *{
        width: 100%;
      }

      > .items{
        @include responsive($tabletSmall){
          max-width: space(10);
        }

        > p{
          padding: 3em 0;

          &[aria-hidden="true"] {
            display: none;
          }
        }
      }

      #map{
        position: relative;
        background: $white;
        overflow: hidden;
        height: 80dvh;
      
        @include responsive($tabletSmall){
          height: 100dvh;
          position: sticky;
          top: 0;
          max-width: space(12);
          margin-left: auto;
          margin-right: space(-1);
        }
      }

      .mapboxgl-canvas{
        outline: none;
      }

      .mapboxgl-ctrl-bottom-left, 
      .mapboxgl-ctrl-bottom-right { 
        display: none; 
      }

      .mapboxgl-popup {
        max-width: none !important;
        padding: 3em;

        .mapboxgl-popup-tip { display: none; }

        .mapboxgl-popup-content {
          background: none;
          padding: 0;
          box-shadow: none;
          border-radius: 0;
        }
      }

      .mapboxgl-popup-close-button{
        position: absolute;
        top: 1em; right: 1em;
        z-index: 1;
        background: $white;
        color: $white;
        border: none;
    
        &:before,
        &:after{
          background: $blue-d1;
        }
      }

      .marker {
        cursor: pointer;
        width: get-vw(28);
        height: get-vw(36);
        background-image: url("data:image/svg+xml,%3Csvg width='28' height='36' viewBox='0 0 28 36' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14 0.5C10.3545 0.504136 6.85958 1.95412 4.28185 4.53185C1.70412 7.10958 0.254136 10.6045 0.25 14.25C0.25 26.0156 12.75 34.9016 13.2828 35.2734C13.493 35.4207 13.7434 35.4996 14 35.4996C14.2566 35.4996 14.507 35.4207 14.7172 35.2734C15.25 34.9016 27.75 26.0156 27.75 14.25C27.7459 10.6045 26.2959 7.10958 23.7182 4.53185C21.1404 1.95412 17.6455 0.504136 14 0.5ZM14 9.25C14.9889 9.25 15.9556 9.54325 16.7779 10.0927C17.6001 10.6421 18.241 11.423 18.6194 12.3366C18.9978 13.2502 19.0969 14.2555 18.9039 15.2255C18.711 16.1954 18.2348 17.0863 17.5355 17.7855C16.8363 18.4848 15.9454 18.961 14.9755 19.1539C14.0055 19.3469 13.0002 19.2478 12.0866 18.8694C11.173 18.491 10.3921 17.8501 9.84265 17.0279C9.29325 16.2056 9 15.2389 9 14.25C9 12.9239 9.52678 11.6521 10.4645 10.7145C11.4021 9.77678 12.6739 9.25 14 9.25Z' fill='%232E6C7D'/%3E%3C/svg%3E%0A");
        background-size: cover;
        background-repeat: no-repeat;
        margin-top: 1em;

        @include responsive($tabletSmall, max){
          min-width: 28px;
          min-height: 36px;
        }

        @include responsive($desk){
          max-width: 28px;
          max-height: 36px;
        }

        &.--open {
          z-index: 30;
          background-image: url("data:image/svg+xml,%3Csvg width='28' height='36' viewBox='0 0 28 36' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14 0.5C10.3545 0.504136 6.85958 1.95412 4.28185 4.53185C1.70412 7.10958 0.254136 10.6045 0.25 14.25C0.25 26.0156 12.75 34.9016 13.2828 35.2734C13.493 35.4207 13.7434 35.4996 14 35.4996C14.2566 35.4996 14.507 35.4207 14.7172 35.2734C15.25 34.9016 27.75 26.0156 27.75 14.25C27.7459 10.6045 26.2959 7.10958 23.7182 4.53185C21.1404 1.95412 17.6455 0.504136 14 0.5ZM14 9.25C14.9889 9.25 15.9556 9.54325 16.7779 10.0927C17.6001 10.6421 18.241 11.423 18.6194 12.3366C18.9978 13.2502 19.0969 14.2555 18.9039 15.2255C18.711 16.1954 18.2348 17.0863 17.5355 17.7855C16.8363 18.4848 15.9454 18.961 14.9755 19.1539C14.0055 19.3469 13.0002 19.2478 12.0866 18.8694C11.173 18.491 10.3921 17.8501 9.84265 17.0279C9.29325 16.2056 9 15.2389 9 14.25C9 12.9239 9.52678 11.6521 10.4645 10.7145C11.4021 9.77678 12.6739 9.25 14 9.25Z' fill='%2368872D'/%3E%3C/svg%3E%0A");
        }
      }
    }

    > .Pagination{
      margin-top: 2.5em;

      > .Btn{
        margin-top: 0;
      }
    }
  }
}
