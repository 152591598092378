.Header {
  position: fixed;
  top: 0; left: 0;
  width: 100%;
  z-index: 120;
  pointer-events: none;

  body:not(.--animating) &{
    transition: transform 750ms $Power3EaseOut;
  }

  &.--hide{
    transform: translate(0, -100%);
  }

  &.--scroll{
    body:not(.--show-menu):not(.--show--submenu):not(.--show-search) &:not(.--hide) {
      .container {
        &:after{
          opacity: 1;
        }
      }
    }
  }

  .container {
    @include flex(center);
    padding-top: 1em;
    padding-bottom: 1em;
    gap: 1.5em;
    transition: background-color 750ms $Power3EaseOut,
                transform 750ms $Power3EaseOut;

    @include responsive($menuBreakpoint, max){
      padding-left: 1em;
      padding-right: 1em;
    }

    &:after{
      @include content();
      @include cover();
      background: rgba($white, 0.75);
      backdrop-filter: blur(6px);
      transition: opacity 750ms $Power3EaseOut;
      opacity: 0;
    }
  }
}
