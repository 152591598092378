.Footer {
   > .main{
      padding: 5em 0;

      .container{
         @include flex(flex-start);
         flex-wrap: wrap;

         @include responsive($tabletSmall, max){
           gap: 2em;
         }

         > *{
            width: 100%;
         }
         
         > .head{
            @include responsive($tabletSmall, max){
              flex: 1 0 100%;
            }

            @include responsive($tabletSmall){
               max-width: space(8);
            }
         }

         > nav:not(.Socials){
            @include responsive($tabletSmall){
               max-width: space(4);
               margin-left: space(1);
            }

            @include responsiveMinMax($mobile, $tabletSmall) {
               flex: 1 0 40%;
            }

            &:nth-child(2){
               @include responsive($tabletSmall){
                 margin-left: auto;
               }
            }

            > .subtitle{
               filter: opacity(0.6);
               margin-bottom: 1em;
            }

            > ul{
               display: grid;
               grid-gap: 0.65em;

               .Link{
                  font: 900 16px $main-font;
                  @include responsive-font(16);
               }
            }
         }

         > .Socials{
            @include responsive($tabletSmall){
               @include flex(flex-start, flex-end);
               max-width: space(2);
            }
         }
      }
   }

   > .sub{
      border-top: 1px dashed rgba($white, 0.2);
      padding: 2em 0;

      .container{
         justify-content: space-between;

         @include responsive($mobile, max){
            flex-direction: column;
         }

         @include responsive($tabletSmall, max){
            gap: 0.5em;
          }

         &, 
         > nav ul,
         > div{
            @include flex(center);
            flex-wrap: wrap;
            filter: opacity(0.6);
         }

         > nav ul,
         > div{
            gap: 1.5em;
            font: 500 12px $main-font;
            @include responsive-font(12);
         }

         > nav ul{
            .Link{
               font-weight: 900;
            }
         }
      }
   }
}
