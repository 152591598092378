.OurStory{
  background-color: #222A35;

  .ScrollContainer{
    // @include flex(center);
    // flex-wrap: nowrap;
    white-space: nowrap;
    // height: 100dvh;

    .Illustration{
      position: relative;
      aspect-ratio: 1200/185;
      min-width: 300vw;
      // outline: 1px solid red;

      @include responsive($tabletSmall, max){
        min-width: 4000px;
      }

      svg{
        @include cover();
      }
    }
  }
}

