.form-infos{
  @include flex(flex-start);
  gap: 1.5em;
  margin-top: 2.5em;

  @include responsive($tabletSmall, max){
    flex-direction: column;
  }

  @include responsive($tabletSmall){
    align-items: center;
  }

  .Newsletter &{
    display: none;
  }

  // .TechnicalSheetForm &,
  // .ContactForm &,
  // .JobApply &{
  //   @include responsive($tabletSmall){
  //     margin-top: -2.5em;
  //   }
  // }

  > p{
    font: 400 10px $main-font;
    @include responsive-font(10);
    max-width: 45em;

    a{
      font-weight: 900;
      text-decoration: underline;
    }
  }

  > .Form__actions{
    @include responsive($tabletSmall){
      margin-left: auto;
    }

    > .Btn{
      margin-top: 0;
    }
  }

  .Form__output:not(:empty){
    text-align: left;
  }
}