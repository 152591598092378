.NavSubmenu[data-furnitures]{
  > .inner{
    @include flex(flex-start);

    @include responsive($menuBreakpoint, max){
      gap: 2em;
      flex-direction: column;
    }
  }

  > .inner > .wrapper{
    .grid{
      display: grid;
      grid-gap: 2em;
      
      @include responsive($menuBreakpoint){
        grid-template-columns: repeat(3, 1fr);
        max-width: space(15);
      }

      .item{
        > .Link{
          font: 500 24px $main-font;
          @include responsive-font(24);
          line-height: 1.3;
        }

        > ul{
          @include flex(flex-start);
          flex-direction: column;
          gap: 0.5em;
          margin-top: 1em;
          filter: opacity(0.8);
        }
      }
    }

    > .Btn{
      margin-top: 2em;

      @include responsive($menuBreakpoint){
        margin-top: 5em;
      }
    }
  }

  > .inner > .BoxCard{
    padding: 2em;

    @include responsive($menuBreakpoint){
      margin-left: auto;
      max-width: get-vw(314);
    }

    @include responsive($desk){
      max-width: 314px;
    }

    > .visual__container{
      max-width: 120px;

      @include responsive($menuBreakpoint){
        max-width: get-vw(120);
      }

      @include responsive($desk){
        max-width: 120px;
      }
    }
  }
}
