.Btn {
  display: inline-flex;
  align-items: center;
  gap: 0.5em;
  padding: 0.7em 1em;
  text-align: center;
  background: $green;
  color: $white;
  outline: 0;
  border: 0;
  cursor: pointer;
  
  body:not(.--animating) &{
    transition: transform 450ms $Power3EaseOut;
  }

  @include noTouchHover() {
    transform: scale(0.95);
  }

  &.--outline{
    background: none;
    border: 1px solid $green;
    color: $green;
  }

  &.--blue{
    background: $blue;
  }

  &.--white{
    background: $white;
    color: $green;

    .Icon.--arrow-alt svg > *{
      stroke: $green;
    }
  }

  &.--beige{
    background: $beige;
    color: $blue;

    .Icon.--arrow-alt svg > *{
      stroke: $blue;
    }
  }

  > span{
    font: 900 14px $main-font;
    color: inherit;
    @include responsive-font(14);
  }

  > .Icon.--arrow-alt{
    margin-top: 0.15em;
  }
}
